<template>
  <div v-if="notPaidPaymentCredits.length > 0">
    <div v-if="loading" class="text-center">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <div v-else>
      <template v-for="paymentCredit in notPaidPaymentCredits">

        <div :key="paymentCredit.id" class="my-2 d-flex align-center">
          <div>
            <v-row>
              <v-col>
                <div class="text-overline font-weight-bold" style="font-size: 16pt !important">€
                  {{paymentCredit.amount}}</div>
              </v-col>
              <v-col cols="auto">
                <div class="mt-1 d-flex">
                  <v-btn icon @click="payCredit(paymentCredit)">
                    <v-icon>mdi-arrow-right</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <div class="text" v-if="!!paymentCredit.schedulePaymentsId"> Rata da pagare </div>
            <div class="text" v-else> Codice scontrino : {{paymentCredit.documentNumber}} </div>
            <div class="text-caption">
              Maturato il {{dateToString("dd/mm/yyyy", new Date(paymentCredit.openedAt))}}<span
                v-if="!!paymentCredit.type">{{typeString(paymentCredit)}}</span>
            </div>
            <div v-if="!!paymentCredit.bills && !paymentCredit.schedulePaymentsId"
              style="display: flex; flex-direction: row">
              <div class="text-caption" style="margin-right: 5px; margin-top: 7px">
                Servizi/Prodotti:
              </div>
              <div v-for="bill in paymentCredit.bills" :key="bill.id"
                style="display: flex; flex-direction: row; flex-wrap: wrap; gap: 2px">
                <div v-for="billService in bill.billServices" :key="billService.id">
                  <div :key="billService.service.id" style="margin-right: 2px">
                    <v-chip>
                      {{billService.service.name}}
                      (
                      <span v-for="billServiceOperator in billService.billServiceOperators"
                        :key="billServiceOperator.id">
                        {{billServiceOperator.operator.firstname}} {{billServiceOperator.operator.lastname}},
                      </span>
                      )
                    </v-chip>
                  </div>
                </div>
                <div v-for="billItem in bill.billItems" :key="billItem.id">
                  <div :key="billItem.item.id" style="margin-right: 2px">
                    <v-chip>
                      {{billItem.item.description}}
                    </v-chip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <v-divider :key="paymentCredit.id + '-divider'"></v-divider>
      </template>
    </div>
  </div>
  <div v-else class="text-center font-weight-light">
    Nessun pagamento in sospeso
  </div>
</template>

<script>
import customersService from '@/services/customers/customers.service.js'
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import dateUtils from '@/mixins/common/dateUtils'

export default {
  name: "PaymentCreditForm",
  components: {
    TypeDataTable
  },
  mixins: [dateUtils],
  data: function () {
    return {
      loading: false,
      paymentCredits: [],
    }
  },
  props: {
    customer: {
      type: Object
    }
  },
  mounted: function () {
    this.loadPaymentCredits()
  },
  methods: {
    loadPaymentCredits() {
      if (!!this.customer && !!this.customer.id) {
        customersService.getPaymentCredits(this.customer, [
          { type: 'custom', operator: 'custom', field: 'status', name: 'Stato', label: 'Stato', color: "", value: ['beginning'], icon: "mdi-list-status" },  
          { type:'custom', operator:'custom', field: 'billStatus', name: 'Stato Scontrino', label: 'Stato Scontrino', color: "", value: {
            billServiceStatus: 'notPaid',
            billItemStatus: 'notPaid',
          }, icon: "mdi-list-status" }
        ]).then((results) => {
          this.paymentCredits = results.rows
          for (let i = 0; i < this.paymentCredits.length; i++) {
            if (this.paymentCredits[i].bills.length != 0) {
              let currentTotalCredit = 0
              for (let k = 0; k < this.paymentCredits[i].bills[0].billServices.length; k++) {
                currentTotalCredit = currentTotalCredit + (Number(this.paymentCredits[i].bills[0].billServices[k].price) * Number(this.paymentCredits[i].bills[0].billServices[k].quantity))
              }
              for (let j = 0; j < this.paymentCredits[i].bills[0].billItems.length; j++) {
                currentTotalCredit = currentTotalCredit + (Number(this.paymentCredits[i].bills[0].billItems[j].price) * Number(this.paymentCredits[i].bills[0].billItems[j].quantity))
              }
              this.paymentCredits[i].amount = currentTotalCredit.toFixed(2)
            }
          }
        })
      }
    },
    typeString(paymentCredit) {
      if (paymentCredit.type == 'cashDesk') {
        return ", da un conto in cassa"
      } else if (paymentCredit.type == 'customerCards_transaction') {
        return ", da una ricarica tessera"
      } else {
        return undefined
      }
    },
    payCredit(paymentCredit) {
      this.$router.push({
        name: 'PayPaymentCredit',
        params: {
          paymentCredit: paymentCredit,
          paymentCreditId: paymentCredit.id,
          pathToGoBack: "Homepage",
          customer: this.customer
        },
      })
    }
  },
  computed: {
    notPaidPaymentCredits() {
      return this.paymentCredits.filter(pc => pc.status != 'finished')
    }
  },
  watch: {
    customer() {
      this.loadPaymentCredits()
    }
  }
}
</script>

<style>

</style>