<template>
  <div class="d-flex flex-column">
    <div class="px-2 pt-1 d-flex header">
      <AddCustomerDialog
        :dialog.sync="localAddCustomerDialog"
        @add="handleCustomerAdded"
        @update:dialog="$emit('update:add-customer-dialog', $event)"
        :saloon-ctrl="true"
        :disabled="disabled"
        :external-customer-search="customerFromVoice"
      ></AddCustomerDialog>
      <div class="ml-auto d-flex">
        <v-slide-x-transition>
          <v-text-field
            solo-inverted
            dense
            hide-details="auto"
            class="mx-2"
            style="max-width: 100px"
            v-model="searchText"
            v-if="searching"
          ></v-text-field>
        </v-slide-x-transition>
        <v-btn
          icon
          class="ml-auto"
          v-on:click="searching = !searching"
          :disabled="disabled"
        >
          <v-icon>{{searching ? 'mdi-close' : 'mdi-account-search'}}</v-icon>
        </v-btn>
      </div>
    </div>
    <div style="height: 100%; overflow-y: auto" ref="wrapper">
      <TypeDataTable
        class="medium"
        ref="table"
        v-model="selected"
        :headers="headers"
        :items="filteredBills"
        item-key="id"
        fixed-header
        :height="dataTableHeight"
        disable-pagination
        hide-default-footer
        no-data-text="Ancora nessun cliente in salone"
        :show-select="false"
        single-select
        :row-per-page="100"
        @delete="handleDelete"
        @edit="handleEdit"
        @input="handleSelection"
        :loading="loading"
      >
        <template v-slot:custom-birthdayCake="{ item: bill }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon large color="#F00070" v-on="on" v-bind="attrs" v-if="checkBirthdayDate(bill.customer.birthday)">mdi-cake-variant</v-icon>
            </template>
            <span>Compleanno</span>
          </v-tooltip>
        </template>
        <template v-slot:custom-firstname="{ item: bill }">
          {{bill.customer.firstname}}
        </template>
        <template v-slot:custom-newCustomerPromo="{ item: bill }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon large color="primary" v-on="on" v-bind="attrs" v-if="bill.customer.newCustomer">mdi-star</v-icon>
            </template>
            <span>Nuovo cliente</span>
          </v-tooltip>
        </template>
        <template v-slot:custom-highPriority="{ item: bill }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon large color="#9c0808" v-on="on" v-bind="attrs" v-if="!!bill.eventGroup && !!bill.eventGroup.highPriority">mdi-alert</v-icon>
              </template>
            <span>Alta priorità</span>
          </v-tooltip>
        </template>
        <template v-slot:custom-infoOnEvent="{ item: bill }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon large color="blue" v-on="on" v-bind="attrs" v-if="checkInfoOnEventGroup(bill)">mdi-information</v-icon>
            </template>
            <span>{{getInfoOnEventGroup(bill)}}</span>
          </v-tooltip>
        </template>
        <template v-slot:custom-isJunior="{ item: bill }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon large color="red" v-on="on" v-bind="attrs" v-if="checkJunior(bill.customer)">mdi-alpha-j-circle</v-icon>
            </template>
            <span>Minorenne</span>
          </v-tooltip>
        </template>
      </TypeDataTable>
    </div>
  </div>
</template>

<script>
import AddCustomerDialog from '@/components/homepage/AddCustomerDialog'
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import billService from '@/services/bills/bills.service.js'
import eventGroupService from '@/services/calendar/eventGroup.service.js'
import Vue from 'vue'

export default {
  name: "CustomersListSection",
  components: {
    AddCustomerDialog,
    TypeDataTable
  },
  data: function () {
    return {
      localAddCustomerDialog: this.addCustomerDialog,
      searching: false,
      dataTableHeight: undefined,
      searchText: undefined,
      headers: [],
      bills: [],
      selected: [],
      todayEventGroups: [],
      loading: false
    }
  },
  props: {
    addCustomerDialog: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => ({})
    },
    bus: {
      type: Object,
      default: new Vue()
    },
    disabled: {
      type: Boolean,
      default: false
    },
    customerFromVoice: {
      type: String,
      default: undefined
    }
  },
  mounted: function() {
    this.calculateTableHeight()
    this.loading = true
    this.reloadBills().then(() => {
      if(this.value) {
        this.selected = [this.value]
      }
      this.loading = false
    })
    this.loadBillsHeaders()
    this.loadTodayEventsGroup()

    const handler = this.calculateTableHeight.bind(this)
    window.addEventListener('resize', handler)

    this.$on("hook:destroyed", () => window.removeEventListener('resize', handler));

    this.bus.$on('reload-bills', () => {
      this.loading = true
      this.reloadBills().then(() => {
        if(this.value) {
          for(let i = 0; i < this.filteredBills.length; i += 1) {
            if(this.filteredBills[i].id == this.value.id) {
              this.selected = [this.filteredBills[i]]
            }
          }
          this.$nextTick(() => {
            this.$emit('input', this.selected[0])
          })
        }
        this.selected = []
        this.loading = false
      })
    })

    let todayBillCachedChangeHandler = () => {
      this.bus.$emit('reload-bills')
    }
    billService.addListener('today-bills-cached-changes', todayBillCachedChangeHandler)
    this.$on("hook:destroyed", () => {
      billService.removeListener('today-bills-cached-changes', todayBillCachedChangeHandler)
    })
  },
  methods: {
    async handleCustomerAdded() {
      if(!billService.socketConnected) {
        await billService.reloadTodayCachedList({ filters: {type:'service'}})
        this.reloadBills()
      }
    },
    extractCustomerFromBill(bill) {
      if(!!bill && !!bill.billCustomers && bill.billCustomers.length != 0) {
        return bill.billCustomers[0].customer
      }
    },
    reloadBills() {
      this.loading = true
      return new Promise((resolve, reject) => {
        billService.todayCachedList({ filters: { type: 'service' }}).then((response) => {
          for(let i = 0; i < response.length; i += 1) {
            response[i].customer = this.extractCustomerFromBill(response[i])
          }
          this.bills = response
          this.bus.$emit('update-bills', this.bills)
          this.loading = false
          resolve(response)
        }).catch((error) => {
          this.loading = false
          reject(error)
        })
      })
    },
    loadTodayEventsGroup() {
      let todayStart = new Date()
      todayStart.setHours(0)
      todayStart.setMinutes(0)
      todayStart.setSeconds(0)
      todayStart.setMilliseconds(0)

      let todayEnd = new Date()
      todayEnd.setHours(23)
      todayEnd.setMinutes(59)
      todayEnd.setSeconds(59)
      todayEnd.setMilliseconds(999)
      
      eventGroupService.list(todayStart, todayEnd).then((results) => {
        this.todayEventGroups = results
      })
    },
    loadBillsHeaders() {
      billService.fieldsForCustomers().then((headers) => {
        this.headers = headers
      })
    },
    nameMatch(name) {
      return (name.toLowerCase().includes(this.searchText.toLowerCase()))
    },
    handleSelection(bills) {
      this.$emit('input', bills[0])
    },
    async handleDelete(item) {
      this.loading = true
      const ctrl = await billService.getBillFromCustomer(item.customer.id)
      if(!ctrl)
        billService.deleteBill({id: item.id}).then(async (results) => {
          if(this.selected.map(el => el.id).includes(item.id)) {
            this.selected = []
            this.$emit('input', undefined)
          }
          
          if(!billService.socketConnected) {
            await billService.reloadTodayCachedList({ filters: {type:'service'}})
            this.reloadBills()
          }
        }).catch(async (err) => {
          if(err == 'event group still present') {
            this.$delegates.snackbar("Eliminare l'appuntamento in agenda prima di rimuovere il cliente")
            this.loading = false
          } else {
            await billService.reloadTodayCachedList({ filters: {type:'service'}})
            this.reloadBills()
            this.loading = false
          }
        })
      else {
        this.$delegates.snackbar("C'è un conto aperto nel bar")
        this.loading = false
      }
    },
    handleEdit(item) {
      const customer = item.billCustomers[0].customer
      this.$router.push({
        name: "CustomersRegistryEditForm",
        params: {
          id: customer.id,
        },
        query: {
          pathToGoBack: this.$router.resolve({ name: "Homepage" }).href,
        },
      });
    },
    calculateTableHeight() {
      let wrapper = this.$refs.wrapper
      this.dataTableHeight = wrapper.offsetHeight
    },
    checkBirthdayDate(birthdayCustomer) {
      let localBirthday = new Date(birthdayCustomer)
      let birthday = new Date(localBirthday.getFullYear(), localBirthday.getMonth(),localBirthday.getDate())


      let localToday = new Date()  

      return birthday.getDate() === localToday.getDate() && birthday.getMonth() === localToday.getMonth()
    },
    checkHighPriority(billCustomerId) {
      return this.todayEventGroups.filter((eventGroup) => Number(eventGroup.customerId) === billCustomerId && eventGroup.highPriority).length > 0
    },
    checkInfoOnEventGroup(bill){
      return !!bill.eventGroup && !!bill.eventGroup.notes
    },
    getInfoOnEventGroup(bill){
      return !!bill.eventGroup ? bill.eventGroup.notes : 'Note'
    },
    checkJunior(customer){
      return this.getAge(customer.birthday) < 18
    },
    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
      }
      return age;
    }
  },
  computed: {
    searchBlank() {
      return this.searchText == undefined || this.searchText == null || this.searchText == ''
    },
    filteredBills() {
      return this.bills.filter((bill) => {
        return this.searchBlank || this.nameMatch(bill.customer.lastname) || this.nameMatch(bill.customer.firstname)
      })
    }
  },
  watch: {
    bills() {
      this.calculateTableHeight()
    },
    addCustomerDialog(newVal) {
      this.localAddCustomerDialog = true
    },
    value(newBill) {
      this.selected = [newBill]
    }
  }
}
</script>

<style scoped>
.wrapper {
  height: 100%;
}

.header {
  height: 40px;
}

.table {
  overflow-y: auto;
  min-height: 100%;
}
</style>