import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'

class BillServiceService {

  list(filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "payments/billServices/list", { filters })
        .then((response) => {
          if (response.success) {
            resolve(response.results)
          } else {
            reject(response.results)
          }
        });
    });
  }


}

export default new BillServiceService();