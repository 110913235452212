import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'

class tableManagment {
  list() {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/bar/tables/list").then((response) => {
        if (response.success) {
          resolve(response.results);
        }
      });
      // resolve(tablesData);
    });
  }

  create(table) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/bar/tables/create", table).then((response) => {
        if (response.success) {
          resolve(response.results);
        } else {
          reject(response.results.error);
        }
      });
    });
  }

  update(table) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/bar/tables/update", table).then((response) => {
        if (response.success) {
          resolve(response.results);
        } else {
          reject("error during the call");
        }
      });
    });
  }

  get(tableId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "/bar/tables/get", { id: tableId })
        .then((response) => {
          resolve(response.results);
        });
    });
  }

  removeBillFromTable(table, type, cashDesk) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/bar/tables/removeBillFromTable", { id: table.id, type: type, cashDesk: cashDesk })
        .then((response) => {
          resolve(response.results);
        });
    });
  }

  allTableCode() {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/bar/tables/allTableCode")
        .then((response) => {
          resolve(response.results);
        });
    });
  }
}
export default new tableManagment();
