<template>
  <v-form v-model="formValid">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-if="!discountValue"
            v-model="discountPercentage"
            label="Percentuale"
            min="0"
            max="100"
            suffix="%"
            type="number"
            :rules="[presenceRule, mustBePercentage]"
            @input="updateObject"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-if="!discountPercentage"
            v-model="discountValue"
            label="Valore"
            type="number"
            min="0"
            :max="localMaxPrice"
            :rules="[presenceRule, mustBePositive, mustBeMinorThanMaxPrice]"
            @input="updateObject"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import billBarCalculator from "@/services/bills/billBar.calculator.js"

export default {
  name: "DiscountFormBar",
  components: {
  },
  data: function() {
    return {
      discountPercentage: undefined,
      discountValue: undefined,
      quantitySelected: undefined,
      localMaxPrice: undefined,
      formValid: false,
      modeSelected: 0,
      presenceRule: (v) => {
        return !!v || 'Il campo è obbligatorio!'
      },
      mustBePositive: (v) => {
        if (v >= 0)
          return true
        else
          return 'Il valore deve essere positivo!'
      },
      mustBePercentage: (v) => {
        if (v >= 0 && v <= 100)
          return true
        else 
          return 'Inserire una percentuale valida!'
      },
      mustBeMinorThanMaxPrice: (v) => {
        if (v <= this.localMaxPrice)
          return true
        else
          return 'Il valore deve essere minore del totale!'
      }
    }
  },
  props: {
    value: {},
    maxPrice: {
      type: Number,
      default: 0
    }
  },
  mounted: function() {
    let discount = billBarCalculator.discountBar
    if (!!discount)
      this.initializeVariables(discount)

    this.localMaxPrice = this.maxPrice
  },
  methods: {
    initializeVariables(value) {

      this.discountValue = undefined
      this.discountPercentage = undefined

      if (value.editPriceType == 'percentage') {
        this.discountPercentage = value.editPriceValue
      }
      else if (value.editPriceType == 'number')
        this.discountValue = value.editPriceValue
    
    },
  updateObject() {
      let localEditPriceType
      let localEditPriceValue

      if (!!this.discountPercentage) {
        localEditPriceType = 'percentage'
        localEditPriceValue = Number(this.discountPercentage)
      }
      else if (!!this.discountValue) {
        localEditPriceType = 'number'
        localEditPriceValue = Number(this.discountValue)          
      }

      this.$emit('input', {
        'editPriceType': localEditPriceType,
        'editPriceValue': localEditPriceValue,
      })
    }
  },
  watch: {
    value(newVal) {
      this.initializeVariables(newVal)
    },
    formValid(newVal) {
      this.$emit('updateValid', newVal)
    },
    maxPrice(newVal) {
      this.localMaxPrice = newVal
    }
  }
}
</script>