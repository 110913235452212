<template style="overflow-y: hidden">
  <v-container fluid>
    <v-row>
      <v-col cols="6" style="display: flex; flex-wrap: wrap" class="d-flex justify-center">
        <div class="text-h6 ma-1 d-flex justify-center">Storico Servizi Parrucchiere</div>
        <div style="overflow-y: auto; width: 100%; height: 30vh">
          <TypeDataTable
            :headers="localHeaders"
            :items="historyBarberServices"
            :showSelect="false"
            :showActions="false"
            :disablePagination="true"
            :loading="historyBarberServicesLoading"
            width="100%"
          >
            <template v-slot:custom-date="{ item }">
              {{item.createdAt.substring(0, item.createdAt.indexOf('T'))}}
            </template>
            <template v-slot:custom-operator="{ item }">
              {{ getOperatorsStringService(item) }}
            </template>
            <template v-slot:custom-name="{ item }">
              {{item.service.name}}
            </template>
          </TypeDataTable>
        </div>
      </v-col>

      <v-divider vertical></v-divider>

      <v-col cols="6" style="display: flex; flex-wrap: wrap" class="d-flex justify-center">
        <div class="text-h6 ma-1 d-flex justify-center">Storico Servizi Estetista</div>
        <div style="overflow-y: auto; width: 100%; height: 30vh">
          <TypeDataTable
            :headers="localHeaders"
            :items="historyBeautyServices"
            :showSelect="false"
            :showActions="false"
            :disablePagination="true"
            :loading="historyBeautyServicesLoading"
            width="100%"
          >
            <template v-slot:custom-date="{ item }">
              {{item.createdAt.substring(0, item.createdAt.indexOf('T'))}}
            </template>
            <template v-slot:custom-operator="{ item }">
              {{ getOperatorsStringService(item) }}
            </template>
            <template v-slot:custom-name="{ item }">
              {{item.service.name}}
            </template>
          </TypeDataTable>
        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="6" style="display: flex; flex-wrap: wrap" class="d-flex justify-center mb-2">
        <div class="text-h6 ma-1 d-flex justify-center">Storico Prodotti Parrucchiere</div>
        <div style="overflow-y: auto; width: 100%; height: 30vh">
          <TypeDataTable
            :headers="localHeaders"
            :items="historyBarberItems"
            :showSelect="false"
            :showActions="false"
            :disablePagination="true"
            :loading="historyBarberItemsLoading"
            width="100%"
          >
            <template v-slot:custom-date="{ item }">
              {{item.createdAt.substring(0, item.createdAt.indexOf('T'))}}
            </template>
            <template v-slot:custom-operator="{ item }">
              {{ getOperatorsStringItem(item) }}
            </template>
            <template v-slot:custom-name="{ item }">
              {{item.item.description}}
            </template>
          </TypeDataTable>
        </div>
      </v-col>

      <v-divider vertical></v-divider>

      <v-col cols="6" style="display: flex; flex-wrap: wrap" class="d-flex justify-center mb-2">
        <div class="text-h6 ma-1 d-flex justify-center">Storico Prodotti Estetica</div>
        <div style="overflow-y: auto; width: 100%; height: 30vh">
          <TypeDataTable
            :headers="localHeaders"
            :items="historyBeautyItems"
            :showSelect="false"
            :showActions="false"
            :disablePagination="true"
            :loading="historyBeautyItemsLoading"
            width="100%"
          >
            <template v-slot:custom-date="{ item }">
              {{item.createdAt.substring(0, item.createdAt.indexOf('T'))}}
            </template>
            <template v-slot:custom-operator="{ item }">
              {{ getOperatorsStringItem(item) }}
            </template>
            <template v-slot:custom-name="{ item }">
              {{item.item.description}}
            </template>
          </TypeDataTable>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import billServiceService from '@/services/bills/billServices.service.js'
import billItemsService from '@/services/bills/billItems.service.js'
import TypeDataTable from "@/components/common/TypeDataTable.vue";

export default {
  name: "CustomerHistoryDashboardForm",
  components: {
    TypeDataTable,
  },
  data: function() {
    return {
      localCustomer: this.customer,
      historyBarberServices: [],
      historyBeautyServices: [],
      historyBarberItems: [],
      historyBeautyItems: [],
      historyBarberServicesLoading: false,
      historyBeautyServicesLoading: false,
      historyBarberItemsLoading: false,
      historyBeautyItemsLoading: false,
      isB: false,
      localHeaders: [
        { text: "Data", value: "date", type: "custom" },
        {
          text: "Nome",
          value: "name",
          type: "custom",
        },
        { text: "Operatore", value: "operator", type: "custom" },
      ],
    }
  },
  props: {
    customer: {
      type: Object,
    },
    items: {
      type: Array,
      default: []
    },
    bus: {
      type: Object,
      default: () => new Vue()
    },
  },
  mounted: function() {
    this.bus.$on('changeB', (value) => {
      this.isB = value
      this.loadHistoryBarberServices()
      this.loadHistoryBeautyServices()
      this.loadHistoryBarberItems()
      this.loadHistoryBeautyItems()
    })


    if(!this.customer || !this.customer.id) {
      throw new Error('at least specify customer id')
    } else {
      this.loadHistoryBarberServices()
      this.loadHistoryBeautyServices()
      this.loadHistoryBarberItems()
      this.loadHistoryBeautyItems()
    }

  },
  methods: {
    loadHistoryBarberServices() {
      this.historyBarberServicesLoading = true
      billServiceService.list({
        customer: this.customer,
        serviceType: 'PARRUCCHIER',
        withOperators: true,
        closed: true,
        showGhost: this.isB
      }).then((result => {
          this.historyBarberServices = result
          this.historyBarberServicesLoading = false
      }))
    },
    loadHistoryBeautyServices() {
      this.historyBeautyServicesLoading = true
      billServiceService.list({
        customer: this.customer,
        serviceType: 'ESTETICA',
        withOperators: true,
        closed: true,
        showGhost: this.isB
      }).then((result => {
          this.historyBeautyServices = result
          this.historyBeautyServicesLoading = false
      }))
    },

    loadHistoryBarberItems() {
      this.historyBarberItemsLoading = true
      billItemsService.list({
        customer: this.customer,
        withOperators: true,
        department: 'PARRUCCHIER',
        closed: true,
        showGhost: this.isB
      }).then((result) => {
        this.historyBarberItems = result
        this.historyBarberItemsLoading = false
      })
    },
    loadHistoryBeautyItems() {
      this.historyBeautyItemsLoading = true
      billItemsService.list({
        customer: this.customer,
        withOperators: true,
        department: 'ESTETICA',
        closed: true,
        showGhost: this.isB
      }).then((result) => {
        this.historyBeautyItems = result
        this.historyBeautyItemsLoading = false
      })
    },
    getOperatorsStringService(billService) {
      let billServiceOperators = billService.billServiceOperators
      if(!!billServiceOperators && billServiceOperators.length > 0) {
        billServiceOperators = billServiceOperators.map((billServiceOperator) => {
          if(!!billServiceOperator.operator)
            return billServiceOperator.operator.firstname + ' ' + billServiceOperator.operator.lastname
          return ''
        })
      }
      return billServiceOperators.join(', ')
    },
    getOperatorsStringItem(billItem) {
      let billItemOperators = billItem.billItemOperators
      if(!!billItemOperators && billItemOperators.length > 0) {
        billItemOperators = billItemOperators.map((billItemOperator) => {
          if(!!billItemOperator.operator)
            return billItemOperator.operator.firstname + ' ' + billItemOperator.operator.lastname
          return ''
        })
      }
      return billItemOperators.join(', ')
    },
  },
  watch: {
    customer() {
      if(!!this.customer && !!this.customer.id) {
        this.loadHistoryBarberServices()
        this.loadHistoryBeautyServices()
        this.loadToday()
        this.loadHistoryItems()
      }
    }
  }
}
</script>