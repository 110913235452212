<template>
  <div class="mb-2">
    <v-card
      class="mr-2"
      color="blue lighten-5"
      :max-height="maxHeight"
      :min-height="minHeight"
    >
      <v-card-text style="">
        <div style="font-size: 15px; font-weight: bold">Scanner Prodotti</div>
        <v-divider></v-divider>
        <v-text-field v-model="codeToAdd"> </v-text-field>
        <div >
          <v-btn depressed color="#077a11" text @click="addItem" :right="true">
            Aggiungi
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <StandardDialog 
      v-model="opendDialog" 
      title="Seleziona Un Oggetto" 
      dialog-height="calc(650-1)"
      dialog-width="75vw"
      persistent>
      
      <div style="height: 100%; overflow-y: hidden" ref="dataTableWrapper">
        <TypeDataTable
          :single-select="true"
          :show-select="true"
          item-key="id"
          fixed-header
          class="mt-10"
          :items="itemsFound"
          :headers="headersItem"
          :show-actions="false"
          @input="handleSelection"
        >
        </TypeDataTable>
      </div>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="dismiss">Annulla</v-btn>
        <v-btn text color="default" @click="addItemFromDialog">Aggiungi</v-btn>
      </template>
    </StandardDialog>

  </div>
</template>

<script>
import StandardDialog from '@/components/common/StandardDialog.vue'
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import menuService from "@/services/bar/menu.service.js"
export default {
  components: {
    StandardDialog,
    TypeDataTable
  },
  props: {
    maxWidht: {
      type: String,
      default: "20vw",
    },
    maxHeight: {
      type: String,
      default: "87vh",
    },
    minWidht: {
      type: String,
      default: "20vw",
    },
    minHeight: {
      type: String,
      default: "87vh",
    },
  },
  mounted() {
    this.fetchItems();
  },
  data() {
    return {
      itemsSelectable: [],
      codeToAdd: undefined,
      opendDialog: false,
      itemsFound: [], 
      itemChosenFromDialog: undefined,
      headersItem: [
        {
          text: "Descrizione",
          value: "description",
        },
        {
          text: "Codice Interno",
          value: "internalCode",
        },
        {
          text: "Barcode",
          value: "code",
        },
      ],
    };
  },
  methods: {
    fetchItems() {
      menuService.list({ withSubcodes: true }).then((menus) => {
        menus.map((menu) => {
          this.itemsSelectable.push(...menu.items);
        });
      });
    },
    addItem() {
      if(!!this.codeToAdd){
      for (const item of this.itemsSelectable) {
        if (item.barcodes.filter((e) => e.code == this.codeToAdd).length > 0) {
          this.itemsFound.push(item)
        }
        else if(item.description.toUpperCase().match(this.codeToAdd.toUpperCase()))
          this.itemsFound.push(item)
        }
        if(this.itemsFound.length == 1){
          this.$emit("add-item", this.itemsFound[0])
          this.itemsFound = []
        }
        else if(this.itemsFound.length > 1) 
          this.opendDialog= true 
      }  
    },
    handleSelection(item){
      this.itemChosenFromDialog = item[0]
    },
    addItemFromDialog(){
      if(!!this.itemChosenFromDialog)
        this.$emit("add-item", this.itemChosenFromDialog)
      this.opendDialog = false
      this.itemsFound = []
    },
    dismiss(){
      this.opendDialog = false
      this.itemsFound = []
    }
  },
  computed: {},
  barcode: {
    scan(barcode) {
      try {
        this.codeToAdd = barcode;
        this.addItem();
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style>
</style>