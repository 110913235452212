<template>
  <FullScreenDialog
    v-model="localDialog"
    title="Paga promozione"
    without-padding
    @input="handleFullScreenDialogChange"
    :can-close="canCloseDialog"
  >
    <template v-slot:content>
      <template v-if="cashDeskOpened">
        <v-stepper v-model="currentStep" height="100%">
          <v-stepper-header>
            <template v-for="(step, index) in steps">
              <v-stepper-step
                :key="step.name"
                :complete="currentStep > index + 1"
                :step="index + 1"
                :editable="currentStep > index + 1 && canChangeStep"
                :disabled="!canChangeStep"
                @click="handleStepClicked(index + 1)"
                edit-icon="mdi-check"
                v-ripple="false"
              >
                {{step.title}}
                <div class="text-caption">{{step.subtitle}}</div>
              </v-stepper-step>
              <v-divider :key="step.name + 'divider'" v-if="index != steps.length - 1"></v-divider>
            </template>
          </v-stepper-header>

          <v-stepper-items>
            <template v-for="(step, index) in steps">
              <v-stepper-content 
                :step="index + 1"
                :key="step.name"
                v-if="step.name == 'invoiceOrReceipt'"
              >
                <div
                  style="height: 70vh; width: 100%"
                  class="d-flex justify-center align-center flex-wrap"
                >
                  <v-card 
                    class="text-h6 d-flex align-center justify-center"
                    height="200"
                    width="200"
                    @click="handleInvoiceClick()"
                  >
                    Fattura
                  </v-card>
                  <v-card
                    class="text-h6 d-flex align-center justify-center ml-2"
                    height="200"
                    width="200"
                    @click="handleReceiptClick()"
                  >
                    Scontrino
                  </v-card>
                </div>
              </v-stepper-content>
               <v-stepper-content
                :step="index + 1"
                v-else-if="step.name == 'invoiceData'"
                :key="step.name"
                class="pa-0"
              >
                <div class="d-flex justify-center align-center flex-column flex-wrap py-3">
                  <InvoiceDataForm
                    :customer="customer"
                    v-model="invoiceData"
                    :valid.sync="invoiceFormValid"
                  ></InvoiceDataForm>
                  <div class="mt-3">
                    <v-btn text color="error" @click="goBackToStep(currentStep - 1)">
                      <v-icon left>mdi-arrow-left</v-icon>
                      Indietro
                    </v-btn>
                    <v-btn 
                      text 
                      color="default"
                      :loading="loadingCustomerUpdate"
                      @click="updateCustomerDataInvoice"
                    >
                      <v-icon left>mdi-content-save</v-icon>
                      Salva sul cliente
                    </v-btn>
                    <v-btn text color="success" @click="goToStep(3)" :disabled="!invoiceFormValid">
                      <v-icon left>mdi-check</v-icon>
                      Conferma
                    </v-btn>
                  </div>
                </div>
              </v-stepper-content>
              <v-stepper-content 
                :step="index + 1"
                :key="step.name"
                v-else-if="step.name == 'summary'"
              >
                <div
                  style="height: 70vh; width: 100%"
                  class="d-flex flex-column justify-center align-center flex-wrap"
                >
                  <PaymentSummary
                    ref="paymentSummary"
                    :total="totalValue"
                    :provided-entities="providedServices"
                    :item-price="(billService) => billService.service.price"
                    :item-name="(billService) => billService.service.name"
                    :opened-cash-desk="openedCashDesk"
                    :paid-with-cash.sync="paidWithCash"
                    :paid-with-card.sync="paidWithCard"
                    :paid-with-tickets.sync="paidWithTickets"
                    :paid-with-check.sync="paidWithCheck"
                    hide-not-paid
                    :number-of="numberOf"
                    :expire-date="finalScheduleDate"
                    :customer="customer"
                    :hide-paid-amounts="invoiceOrReceipt == 'invoice' || invoiceOrReceipt == 'payLater'"
                    @confirm="goToStep(currentStep + 1)" 
                    @go-back="goBackToStep(currentStep - 1)"
                    @dblclick-row="handleDoubleClickRow"
                    :lottery-code.sync="lotteryCode"
                    :use-lottery-code.sync="useLotteryCode"
                  ></PaymentSummary>
                </div>
              </v-stepper-content>
              <v-stepper-content 
                :step="index + 1"
                :key="step.name"
                v-else-if="step.name == 'payment'"
              >
                <div
                  style="height: 70vh;"
                  class="d-flex flex-column justify-center align-center flex-wrap"
                >
                  <div class="d-flex justify-center align-center my-2">
                    <AnimatedCheck
                      class="mx-4"
                      v-model="invoicePrinted"
                    ></AnimatedCheck>
                    <div class="text-h6 text-center" style="width: 500px; max-width: 90vw">
                      {{invoicePrintedMessage}}
                    </div>
                  </div>
                  <div class="d-flex justify-center align-center my-2">
                    <AnimatedCheck
                      class="mx-4"
                      v-model="transactionClosed"
                    ></AnimatedCheck>
                    <div class="text-h6 text-center" style="width: 500px; max-width: 90vw">
                      {{transactionClosedMessage}}
                    </div>
                  </div>
                  <div class="d-flex justify-center align-center my-2">
                    <AnimatedCheck
                      class="mx-4"
                      v-model="dataRegistered"
                    ></AnimatedCheck>
                    <div class="text-h6 text-center" style="width: 500px; max-width: 90vw">
                      {{dataRegisteredMessage}}
                    </div>
                  </div>
                  <div class="d-flex justify-center align-center mt-5">
                    <v-btn icon @click="closeDialog" :disabled="canCloseDialog"></v-btn>
                  </div>
                </div>
              </v-stepper-content>
              <v-snackbar
                v-model="messageError"
                timeout="-1"
              >
                {{ messageErrorText }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="red"
                    text
                    v-bind="attrs"
                    @click="messageError = false"
                  >
                    Chiudi
                  </v-btn>
                </template>
              </v-snackbar>
            </template>
          </v-stepper-items>
        </v-stepper>
      </template>
      <template v-else>
        Non puoi procedere con la cassa chiusa
      </template>
    </template>
  </FullScreenDialog>
</template>

<script>
import Printer from '@/services/devices/rtPrinter'
import cashDeskService from '@/services/cashDesks/cashDesks.service.js'
import StandardDialog from '@/components/common/StandardDialog'
import dateUtils from '@/mixins/common/dateUtils'
import FullScreenDialog from '@/components/common/FullScreenDialog'
import InvoiceDataForm from '@/components/homepage/sections/InvoiceDataForm.vue'
import promosService from '@/services/promos/promos.service.js'
import OperatorAvatar from '@/components/common/OperatorAvatar.vue';
import NumericKeyboardInput from '@/components/common/NumericKeyboardInput.vue';
import AnimatedCheck from '@/components/common/AnimatedCheck.vue';
import PaymentSummary from '@/components/homepage/sections/PaymentSummary.vue'


export default {
  name: "PromosPayDialog",
  components: {
    StandardDialog,
    FullScreenDialog,
    OperatorAvatar,
    NumericKeyboardInput,
    AnimatedCheck,
    PaymentSummary,
    InvoiceDataForm
  },
  mixins: [dateUtils],
  data: function () {
    return {
      invoiceData: undefined,
      invoiceFormValid: false,
      loadingCustomerUpdate: false,
      localPaymentCredit: undefined,
      localDialog: this.dialog,
      currentStep: 1,
      invoiceOrReceipt: undefined,
      totalValue: undefined,
      receiptScrollY: 0,
      openedCashDesk: undefined,
      canScrollReceipt: false,
      paidWithCash: 0,
      paidWithCard: 0,
      paidWithTickets: 0,
      paidWithCheck: 0,
      notPaid: 0,
      transactionClosed: false,
      dataRegistered: false,
      invoicePrinted: false,
      canCloseDialog: true,
      canChangeStep: true,
      numberOf: undefined,
      finalScheduleDate: undefined,
      promoAmount: undefined,
      messageError: false,
      messageErrorText: undefined,
      ghost: false,
      lotteryCode: undefined,
      useLotteryCode: undefined
    }
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    goBack: {
      type: Boolean,
      default: false
    },
    goBackTo: {},
    promo: {},
    customer: {},
    schedulePaymentsSettings: {
      default: undefined
    },
    customerGiftCard: {
      default: undefined
    },
    promoCode: {
      default: undefined
    }
  },
  mounted: function () {
    if (!!this.promo) {
      this.totalValue = Number(this.promo.price)
    }

    if(!!this.customer && !!this.customer.dataInvoices && !!this.customer.dataInvoices[0]) {
      this.lotteryCode = this.customer.dataInvoices[0].lotteryCode
    }

    this.getOpenedCashDesk()
  },
  methods: {
    closeDialog(boughtPromo = false) {
      this.$emit('update:dialog', false)
      this.$emit('reload')
      if (boughtPromo) {
        this.$emit('select-promo', this.promo)
      }
      let goBack;
      if (!!this.goBack) {
        goBack = () => {
          this.$router.go(-1)
        }
        setTimeout(goBack.bind(this), 200);
      } else if (!!this.goBackTo) {
        let goBackTo = { ...this.goBackTo }
        goBack = () => {
          this.$router.push(goBackTo)
        }
        setTimeout(goBack.bind(this), 200);
      }
    },
    getOpenedCashDesk() {
      cashDeskService.getOpened().then((openedCashDesk) => {
        this.openedCashDesk = openedCashDesk
      })
    },
    handleFullScreenDialogChange(newVal) {
      if (!newVal) {
        this.closeDialog()
      }
    },
    startFromScratch() {
      this.invoiceOrReceipt = undefined
      this.currentStep = 1
    },
    setInvoiceOrReceipt(type) {
      this.invoiceOrReceipt = type
    },
    goToStep(number) {
      const step = this.steps[number - 1]
      if (step.name == 'summary') {
        setTimeout(() => {
          this.$refs.paymentSummary[0].calculateScrollReceipt()
        }, 200)
      } else if (step.name == 'payment') {
        setTimeout(() => {
          this.pay()
        }, 1000);
      }
      this.currentStep = number
    },
    goBackToStep(number) {
      const step = this.steps[number - 1]
      if (step.name == 'invoiceOrReceipt') {
        this.invoiceOrReceipt = undefined
      }
      this.goToStep(number)
    },
    handleStepClicked(number) {
      if (this.currentStep == number || !this.canChangeStep) {
        return
      } else if (this.currentStep < number) {
        this.$delegates.snackbar("Confermare prima di avanzare")
      } else {
        this.goBackToStep(number)
      }
    },
    handleInvoiceClick() {
      this.setInvoiceOrReceipt('invoice');
      this.$nextTick(() => {
        this.goToStep(2)
      })
    },
    handleReceiptClick() {
      this.setInvoiceOrReceipt('receipt');
      this.$nextTick(() => {
        this.goToStep(2)
      })
    },
    updateCustomerDataInvoice() {
      if (!!this.customer && !!this.invoiceData) {
        let dataInvoice = { ...this.invoiceData }
        dataInvoice.lastname = dataInvoice.companyNameOrLastname
        delete dataInvoice.companyNameOrLastname

        this.loadingCustomerUpdate = true
        customerService.update({
          id: this.customer.id,
          dataInvoice: dataInvoice
        }).then((results) => {
          this.loadingCustomerUpdate = false
          this.$delegates.snackbar('Salvataggio avvenuto correttamente')
        })
      }
    },
    async pay() {
      try {
        this.transactionClosed = false
        this.dataRegistered = false
        this.invoicePrinted = false
        this.canCloseDialog = false
        this.canChangeStep = false

        if(Number(this.paidWithCash) + (Number(this.paidWithCard) || 0) + (Number(this.paidWithTickets) || 0) + (Number(this.paidWithCheck) || 0) > Number(this.totalValue)) {
        this.paidWithCash = Math.max(Number(this.totalValue) - (Number(this.paidWithCard) || 0) - (Number(this.paidWithTickets) || 0) - (Number(this.paidWithCheck) || 0), 0)
      }

      if(Number(this.paidWithCard) + (Number(this.paidWithTickets) || 0) + (Number(this.paidWithCheck) || 0) > Number(this.totalValue) - (Number(this.paidWithCash) || 0)) {
        this.paidWithCard = Math.max(Number(this.totalValue) - (Number(this.paidWithTickets) || 0) - (Number(this.paidWithCheck) || 0), 0)
      }

      if(Number(this.paidWithTickets) + (Number(this.paidWithCheck) || 0) > Number(this.totalValue) - (Number(this.paidWithCash) || 0) - (Number(this.paidWithCard) || 0)) {
        this.paidWithTickets = Math.max(Number(this.totalValue) - (Number(this.paidWithCheck) || 0), 0)
      }

      if(Number(this.paidWithCheck) > Number(this.totalValue) - (Number(this.paidWithCash) || 0) - (Number(this.paidWithCard) || 0) - (Number(this.paidWithTickets) || 0)) {
        this.paidWithCheck = Number(this.totalValue)
      }

        let paymentTypes = []
        if (!!this.paidWithCash) {
          paymentTypes.push({
            amount: this.paidWithCash,
            type: 'cash'
          })
        }

        if (!!this.paidWithCard) {
          paymentTypes.push({
            amount: this.paidWithCard,
            type: 'card'
          })
        }

        if (!!this.paidWithTickets) {
          paymentTypes.push({
            amount: this.paidWithTickets,
            type: 'ticket'
          })
        }

        if (!!this.paidWithCheck) {
          paymentTypes.push({
            amount: this.paidWithCheck,
            type: 'cheque'
          })
        }

        if (paymentTypes.length == 0) {
          paymentTypes.push({
            amount: 0,
            type: 'cash'
          })
        }

        let rtResponse
        if (this.promo.name == "Gift Card") {
          if (this.ghost) {
            rtResponse = await Printer.printSummary({
              amount: this.totalValue,
              invoiceData: this.invoiceData,
              items: this.providedServices.map(ps => ({
                name: ps.service.description.replace("&", "e"),
                price: this.totalValue,
                quantity: ps.quantity,
              })),
            }, undefined, "gift card ghost receipt")
          } else if (this.invoiceOrReceipt == 'receipt') {
            rtResponse = await Printer.printFiscalReceipt({
              paymentTypes: paymentTypes,
              lotteryCode: this.useLotteryCode ? this.lotteryCode : undefined,
              items: this.providedServices.map(ps => ({
                name: ps.service.description.replace("&", "e"),
                price: ps.service.price,
                quantity: ps.quantity,
              })),
            }, "promo gift card not ghost receipt")
          } else if (this.invoiceOrReceipt == 'invoice') {
            rtResponse = await Printer.printSummary({
              amount: this.totalValue,
              invoiceData: this.invoiceData,
              items: this.providedServices.map(ps => ({
                name: ps.service.description.replace("&", "e"),
                price: ps.service.price,
                quantity: ps.quantity,
              }))
            }, true, "promo gift card not ghost invoice")
          }
        } else {
          if (this.ghost) {
            rtResponse = await Printer.printSummary({
              amount: this.totalValue,
              invoiceData: this.invoiceData,
              items: this.providedServices.map(ps => ({
                name: ps.service.name.replace("&", "e"),
                price: ps.service.price,
                quantity: ps.quantity,
              }))
            }, true, "promo ghost receipt")
          } else if (this.invoiceOrReceipt == 'receipt') {
            rtResponse = await Printer.printFiscalReceipt({
              paymentTypes: paymentTypes,
              lotteryCode: this.useLotteryCode ? this.lotteryCode : undefined,
              items: this.providedServices.map(ps => ({
                name: ps.service.name.replace("&", "e"),
                price: ps.service.price,
                quantity: ps.quantity,
              })),
            }, "promo ghost receipt")
          } else if (this.invoiceOrReceipt == 'invoice') {
            rtResponse = await Printer.printSummary({
              amount: this.totalValue,
              invoiceData: this.invoiceData,
              items: this.providedServices.map(ps => ({
                name: ps.service.name.replace("&", "e"),
                price: ps.service.price,
                quantity: ps.quantity,
              }))
            }, true, "promo ghost invoice")
          }
        }

        if (rtResponse.attributes.success == 'false') {
          this.$delegates.snackbar('Errore durante la stampa dello scontrino')
          this.canCloseDialog = true
          return
        }

        this.invoicePrinted = true

        let documentNumberFixed = undefined
        if (!!rtResponse && !!rtResponse.addInfo) {
          rtResponse.addInfo.zRepNumber = '' + rtResponse.addInfo.zRepNumber
          rtResponse.addInfo.fiscalReceiptNumber = '' + rtResponse.addInfo.fiscalReceiptNumber
          while (rtResponse.addInfo.zRepNumber.length < 4) {
            rtResponse.addInfo.zRepNumber = '0' + rtResponse.addInfo.zRepNumber
          }
          while (rtResponse.addInfo.fiscalReceiptNumber.length < 4) {
            rtResponse.addInfo.fiscalReceiptNumber = '0' + rtResponse.addInfo.fiscalReceiptNumber
          }
          if (!!rtResponse.addInfo.zRepNumber && rtResponse.addInfo.zRepNumber != 'undefined' 
              && !!rtResponse.addInfo.fiscalReceiptNumber && rtResponse.addInfo.fiscalReceiptNumber != 'undefined')
              documentNumberFixed = rtResponse.addInfo.zRepNumber + '-' + rtResponse.addInfo.fiscalReceiptNumber
        }

        let customerToAdd = this.customerGiftCard || this.customer
        customerToAdd = { id:customerToAdd.id, firstname: customerToAdd.firstname, lastname: customerToAdd.lastname }

        let customer = {...this.customer}
        customer = { id:customer.id, firstname: customer.firstname, lastname: customer.lastname }

        if(this.invoiceOrReceipt == 'receipt') {          
          let ghostValue = !!this.schedulePaymentsSettings ? Number(Number((Number(this.promo.price) * Number((Number(this.schedulePaymentsSettings.percetageAdvance) / 100)))).toFixed(2))
            : Number(this.promo.price)

          await promosService.payPromo(this.promo, {
            date: new Date(),
            cashDesk: this.openedCashDesk,
            customer: customer,
            documentType: 'receipt',
            cashPaid: this.paidWithCash,
            cardPaid: this.paidWithCard,
            checkPaid: this.paidWithCheck,
            otherPaid: this.paidWithTickets,
            ghostPaid: !!this.ghost ? ghostValue : 0,
            schedulePaymentsSetting: this.schedulePaymentsSettings,
            promoAmount: this.promoAmount,
            finalScheduleDate: this.finalScheduleDate,
            documentNumber: documentNumberFixed,
            cashDeskLog: this.openedCashDesk.cashDeskLog.id,
            customerToAdd: { id:customerToAdd.id, firstname: customerToAdd.firstname, lastname: customerToAdd.lastname, promosCustomers: undefined},
            promoCode: this.promoCode
          })
        } else if (this.invoiceOrReceipt == 'invoice') {
          let ghostValue = !!this.schedulePaymentsSettings ? Number(Number((Number(this.promo.price) * Number((Number(this.schedulePaymentsSettings.percetageAdvance) / 100)))).toFixed(2))
            : Number(this.promo.price)
          
          let invoiceData = {
            ...this.invoiceData,
            lastname: this.invoiceData.companyNameOrLastname,
            amount: this.totalValue
          }

          delete invoiceData.companyNameOrLastname

          await promosService.payPromo(this.promo, {
            date: new Date(),
            cashDesk: this.openedCashDesk,
            customer: customer,
            documentType: 'invoice',
            dataInvoice: invoiceData,
            cashPaid: this.paidWithCash,
            cardPaid: this.paidWithCard,
            checkPaid: this.paidWithCheck,
            otherPaid: this.paidWithTickets,
            ghostPaid: !!this.ghost ? ghostValue : 0,
            cashDeskLog: this.openedCashDesk.cashDeskLog.id,
            customerToAdd: { id:customerToAdd.id, firstname: customerToAdd.firstname, lastname: customerToAdd.lastname, promosCustomers: undefined},
            promoCode: this.promoCode
          })
        }

        this.canCloseDialog = true
        this.transactionClosed = true
        this.dataRegistered = true

        setTimeout(this.closeDialog(true), 2000)
      } catch (err) {
        if (String(err).includes("URL") || String(err).includes("protocol")) {
          this.messageErrorText = 'Stampante non collegata / Indirizzo stampante errato'
          this.messageError = true
        } else {
          this.messageErrorText = err
          this.messageError = true
        }
      }
    },
    handleDoubleClickRow(item) {
      this.ghost = !this.ghost;
      this.paidWithCash = 0
      this.paidWithCard = 0
      this.paidWithTickets = 0
      this.paidWithCheck = 0
    },
  },
  watch: {
    dialog(newVal) {
      this.localDialog = newVal
      if (newVal) {
        this.startFromScratch()
      }
    },
    promo() {
      if (!!this.promo) {
        if (!!this.schedulePaymentsSettings) {
          this.promoAmount = Number(this.promo.price)
          this.totalValue = Number(Number((Number(this.promo.price) * Number((Number(this.schedulePaymentsSettings.percetageAdvance) / 100)))).toFixed(2))
          this.numberOf = this.schedulePaymentsSettings.numberOf
          this.finalScheduleDate = new Date(new Date().getTime() + ((Number(this.schedulePaymentsSettings.numberOf) * Number(this.schedulePaymentsSettings.daysBetween)) * 24 * 60 * 60 * 1000));
        } else {
          this.totalValue = Number(this.promo.price)
        }
      }
      this.startFromScratch()
    },
    schedulePaymentsSettings() {
      if (!!this.promo) {
        if (!!this.schedulePaymentsSettings) {
          this.promoAmount = Number(this.promo.price)
          this.totalValue = Number(Number((Number(this.promo.price) * Number((Number(this.schedulePaymentsSettings.percetageAdvance) / 100)))).toFixed(2))
          this.numberOf = this.schedulePaymentsSettings.numberOf
          this.finalScheduleDate = new Date(new Date().getTime() + ((Number(this.schedulePaymentsSettings.numberOf) * Number(this.schedulePaymentsSettings.daysBetween)) * 24 * 60 * 60 * 1000));
        } else {
          this.totalValue = Number(this.promo.price)
        }
      }
      this.startFromScratch()
    },
    ghost(newVal) {
      if (newVal) {
        this.totalValue = 0;
      } else {
        if (!!this.schedulePaymentsSettings) {
          this.totalValue = Number(Number((Number(this.promo.price) * Number((Number(this.schedulePaymentsSettings.percetageAdvance) / 100)))).toFixed(2))
        } else {
          this.totalValue = Number(this.promo.price)
        }
      }
    },
    customer(newVal) {
      if(!!newVal && !!newVal.dataInvoices && !!newVal.dataInvoices[0]) {
        this.lotteryCode = newVal.dataInvoices[0].lotteryCode
      }
    }
  },
  computed: {
    steps() {
      let steps = []

      if (!this.invoiceOrReceipt) {
        steps.push({
          title: 'Scontrino o Fattura',
          name: "invoiceOrReceipt"
        })

        steps.push({
          title: 'Dati Fatturazione',
          name: 'invoiceData'
        })
      } else if (this.invoiceOrReceipt == 'invoice') {
        steps.push({
          title: 'Fattura',
          name: "invoiceOrReceipt"
        })

        steps.push({
          title: 'Dati Fatturazione',
          name: 'invoiceData'
        })
      } else if (this.invoiceOrReceipt == 'receipt') {
        steps.push({
          title: 'Scontrino',
          name: "invoiceOrReceipt"
        })
      }

      steps.push({
        title: 'Riepilogo',
        name: 'summary'
      })

      steps.push({
        title: 'Pagamento',
        name: 'payment'
      })

      return steps
    },
    providedServices() {
      if (!this.promo) {
        return []
      } else {
        return [
          {
            id: 1,
            service: {
              name: `Promo: ${this.promo.name}`,
              description: `Promo: ${this.promo.description}`,
              price: this.totalValue,
            },
            ghost: this.ghost,
            ghostQuantity: this.ghost ? 1 : 0,
            quantity: 1
          }
        ]
      }
    },
    cashDeskOpened() {
      return !!this.openedCashDesk
    },
    transactionClosedMessage() {
      return this.transactionClosed ? 'Transazione eseguita correttamente' : 'Transazione in corso ...'
    },
    dataRegisteredMessage() {
      return this.dataRegistered ? 'Dati registrati correttamente' : 'Registrazione dei dati ...'
    },
    invoicePrintedMessage() {
      return this.invoicePrinted ? 'Scontrino stampato correttamente' : 'Stampa dello scontrino ...'
    }
  }
}
</script>

<style>
</style>