<template>
  <StandardDialog
    v-model="localOpenDialog"
    dialog-height="calc(60vh - 1)"
    dialog-width="40vw"
    :title="title"
    persistent
  >
    <div v-if="localPermission" class="mt-3 d-flex justify-center align-center">
      <span class="font-weight-normal"
        >C'è già uno sconto applicato a questo/i servizio/i. Continuare?</span
      >
    </div>

    <v-form v-model="formValid" v-if="!showDiscountBarcode && !localPermission">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="modeSelected"
              :items="modes"
              item-text="value"
              item-value="key"
              label="Seleziona Tipologia"
              :disabled="disabledFromPartialPayment"
              @change="updateObject"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-if="!discountValue"
              v-model="discountPercentage"
              label="Percentuale"
              min="0"
              max="100"
              suffix="%"
              type="number"
              :disabled="disabledFromPartialPayment"
              :rules="[presenceRule, mustBePercentage]"
              @input="updateObject"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-if="!discountPercentage"
              v-model="discountValue"
              label="Valore"
              type="number"
              min="0"
              :max="maxPrice"
              :disabled="disabledFromPartialPayment"
              :rules="[presenceRule, mustBePositive]"
              @input="updateObject"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!--       <v-row>
          <v-select
            v-model="quantitySelected"
            :items="quantityItems"
            label="Quantità"
            :rules="[presenceRule]"
            @input="updateObject"
          ></v-select>
        </v-row> -->
        <v-row justify="center">
          <v-col cols="7"> Prezzo con modifica (singolo): </v-col>
          <v-col cols="2">
            {{ updatedPrice }}
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <ManualBarcodeInput
      v-if="showDiscountBarcode && !localPermission"
      v-model="allowDiscountBarcode"
      @confirm="handleConfirmDiscount"
    >
    </ManualBarcodeInput>

    <template v-slot:footer>
      <v-spacer></v-spacer>
      <v-btn text color="error" @click="() => {
        localOpenDialog = false
        $emit('update:open-dialog', false)}"
        >
          Chiudi
        </v-btn>
      <v-btn
        v-if="!!entityDetail && !!entityDetail.editPriceValue && !!entityDetail.editPriceType &&
          !showDiscountBarcode && !localPermission && priceMode == 'edit'"
        text
        color="default"
        :disabled="disabledFromPartialPayment"
        @click="$emit('remove-discount', type)"
      >
        Resetta
      </v-btn>
      <v-btn
        v-if="!!entityDetail && !!entityDetail.discountValue && !!entityDetail.discountType &&
          !showDiscountBarcode && !localPermission && priceMode == 'discount'"
        text
        color="default"
        :disabled="disabledFromPartialPayment"
        @click="$emit('remove-discount', type)"
      >
        Rimuovi
      </v-btn>
      <v-btn
        text
        color="success"
        v-if="!showDiscountBarcode && !localPermission"
        :disabled="!formValid || !discountValid || disabledFromPartialPayment"
        @click="$emit('set-discount', type)"
      >
        Assegna
      </v-btn>
      <v-btn
        text
        color="default"
        v-if="localPermission"
        @click="() => {
            showDiscountBarcode = true;
            localPermission = false;
            $emit('update:permission', false);
          }"
      >
        Continua
      </v-btn>
    </template>
  </StandardDialog>
</template>

<script>
import StandardDialog from "@/components/common/StandardDialog.vue";
import ManualBarcodeInput from "@/components/common/ManualBarcodeInput.vue";
import operatorService from '@/services/operators/operators.service.js';

export default {
  name: "DiscountForm",
  components: {
    StandardDialog,
    ManualBarcodeInput,
  },
  data: function () {
    return {
      discountPercentage: undefined,
      discountValue: undefined,
      quantitySelected: undefined,
      formValid: false,
      discountValid: true,
      maxPrice: undefined,
      modeSelected: 0,
      localOpenDialog: false,
      localPermission: false,
      showDiscountBarcode: false,
      allowDiscountBarcode: undefined,
      presenceRule: (v) => {
        return !!v || "Il campo è obbligatorio!";
      },
      mustBePositive: (v) => {
        if (v >= 0) return true;
        else return "Il valore deve essere positivo!";
      },
      mustBePercentage: (v) => {
        if (v >= 0 && v <= 100) return true;
        else return "Inserire una percentuale valida!";
      },
    };
  },
  props: {
    value: {},
    entityDetail: {
      type: Object,
      default: () => {},
    },
    serviceDetail: {
      type: Object,
      defualt: () => {},
    },
    itemDetail: {
      type: Object,
      defualt: () => {},
    },
    type: {
      type: String,
      dafault: "",
    },
    priceMode: {
      type: String,
      default: ""
    },
    modes: {
      type: Array,
      default: () => {
        return [
          { key: 0, value: "Riduzione" },
          { key: 1, value: "Maggiorazione" },
        ];
      },
    },
    title: {
      type: String,
      default: "Modifica Prezzo",
    },
    permission: {
      type: Boolean,
      default: false,
    },
    openDialog: {
      type: Boolean,
      default: false,
    },
  },
  mounted: function () {
    this.localOpenDialog = this.openDialog;
    if (!!this.value) this.initializeVariables(this.value);
  },
  methods: {
    initializeVariables(value) {
      this.discountValue = undefined;
      this.discountPercentage = undefined;

      this.modeSelected = value.mode != undefined ? value.mode : 0

      if (this.priceMode == 'edit') {
        if (value.editPriceType == "percentage") {
          this.discountPercentage =
            value.editPriceValue < 0 ? -value.editPriceValue : value.editPriceValue;
        } else if (value.editPriceType == "number")
          this.discountValue =
            value.editPriceValue < 0 ? -value.editPriceValue : value.editPriceValue;
      }

      if (this.priceMode == 'discount') {
        if (value.discountType == "percentage") {
          this.discountPercentage =
            value.discountValue < 0 ? -value.discountValue : value.discountValue;
        } else if (value.discountType == "number")
          this.discountValue =
            value.discountValue < 0 ? -value.discountValue : value.discountValue;
      }

      if (!!this.entityDetail) {
        if (this.type == "service")
          this.maxPrice = this.entityDetail.service.price;
        else if (this.type == "item")
          this.maxPrice = this.entityDetail.item.priceSelling;
      }
      /*       if(!!this.serviceDetail) {
        this.maxPrice = this.serviceDetail.service.price
      } else if (!!!this.itemDetail) {
        this.maxPrice = this.itemDetail.item.priceSelling
      } */
      //this.quantitySelected = Number(value.editPriceValue)
    },
    handleConfirmDiscount() {
      operatorService.canPerformOperation(this.allowDiscountBarcode, "ApplyDiscounts").then((result) => {
        if (result) {
          this.showDiscountBarcode = false
        } else {
          this.$delegates.snackbar('Non hai i permessi per eseguire questa azione!')
        }
      }).catch(() => {
        this.$delegates.snackbar('Badge non riconosciuto')
      })
    },
    updateObject() {
      let localType;
      let localValue;

      if (!!this.discountPercentage) {
        localType = "percentage";
        localValue = Number(this.discountPercentage);
      } else if (!!this.discountValue) {
        localType = "number";
        localValue = Number(this.discountValue);
      }

      if (this.priceMode == 'edit')
        this.$emit("input", {
          editPriceType: localType,
          editPriceValue: localValue,
          mode: this.modeSelected,
        });
      else if (this.priceMode == 'discount')
        this.$emit("input", {
          discountType: localType,
          discountValue: localValue,
          mode: this.modeSelected,
        });
    },
  },
  computed: {
    quantityItems() {
      if (!!this.entityDetail && !!this.entityDetail.quantity) {
        return Array.from(
          new Array(Number(this.entityDetail.quantity)),
          (x, i) => i + 1
        );
      }
      /*       if (!!this.serviceDetail && !!this.serviceDetail.quantity) {
        return Array.from(new Array(Number(this.serviceDetail.quantity)), 
          (x, i) => i + 1
        )
      } else if(!!this.itemDetail){
        return Array.from(new Array(Number(this.itemDetail.quantity)), 
          (x, i) => i + 1
        )
      } */
    },
    updatedPrice() {
      if (!!this.entityDetail) {
        let localPrice =
          this.type == "service"
            ? Number(this.entityDetail.service.price)
            : Number(this.entityDetail.item.priceSelling);

        if (this.priceMode == 'edit') {
          if (!!this.entityDetail.discountType && !!this.entityDetail.discountValue) {
            if (this.entityDetail.discountType == 'number') {
              localPrice -= Number(this.entityDetail.discountValue)
            }
            else if (this.entityDetail.editPriceType == 'percentage'){
              localPrice -= Number((localPrice * (this.entityDetail.discountValue / 100)).toFixed(2))
            }
          }
        }
        else if (this.priceMode == 'discount') {
          if (!!this.entityDetail.editPriceType && !!this.entityDetail.editPriceValue) {
            if (this.entityDetail.editPriceType == 'number') {
              localPrice -= Number(this.entityDetail.editPriceValue)
            }
            else if (this.entityDetail.editPriceType == 'percentage'){
              localPrice -= Number((localPrice * (this.entityDetail.editPriceValue / 100)).toFixed(2))
            }
          }
        }

        let finalPrice = localPrice;

        let modeSelected = this.priceMode == 'edit' && Number(this.entityDetail.editPriceValue) < 0 ? 1 : this.modeSelected

        if (!!this.discountValue) {
          if (
            (this.discountValue > localPrice || this.discountValue < 0) &&
            modeSelected == 0
          ) {
            this.$delegates.snackbar("Sconto non valido!");
            this.discountValid = false;
          } else if (this.discountValue < 0 && modeSelected == 1) {
            this.$delegates.snackbar("Sconto non valido!");
            this.discountValid = false;
          } else {
            this.discountValid = true;
            if (modeSelected == 1)
              finalPrice = (localPrice + this.discountValue).toFixed(2);
            else finalPrice = (localPrice - this.discountValue).toFixed(2);
          }
        } else if (!!this.discountPercentage) {
          if (this.discountPercentage > 100 || this.discountPercentage < 0)
            this.$delegates.snackbar("Sconto non valido!");
          else {
            if (this.modeSelected == 1)
              finalPrice = (
                localPrice +
                localPrice * (this.discountPercentage / 100)
              ).toFixed(2);
            else
              finalPrice = (
                localPrice -
                localPrice * (this.discountPercentage / 100)
              ).toFixed(2);
          }
        }
        return finalPrice;
      } /* else if(!!this.itemDetail){
        let localPrice = Number(this.itemDetail.item.priceSelling)
        let finalPrice = localPrice

        if (!!this.discountValue) {
          if ((this.discountValue > localPrice || this.discountValue < 0) && this.modeSelected == 0) {
            this.$delegates.snackbar('Sconto non valido!')
            this.formValid = false
          }
          else if (this.discountValue < 0 && this.modeSelected == 1) {
            this.$delegates.snackbar('Sconto non valido!')
            this.formValid = false
          }
          else {
            if (this.modeSelected == 1)
              finalPrice = (localPrice + this.discountValue).toFixed(2)
            else
              finalPrice = (localPrice - this.discountValue).toFixed(2)
          }
        }

        else if (!!this.discountPercentage) {
          if (this.discountPercentage > 100 || this.discountPercentage < 0)
            this.$delegates.snackbar('Sconto non valido!')
          else {
            if (this.modeSelected == 1)
              finalPrice = (localPrice + (localPrice * (this.discountPercentage / 100))).toFixed(2)
            else
              finalPrice = (localPrice - (localPrice * (this.discountPercentage / 100))).toFixed(2)
          }
        }
        return finalPrice
      } */
    },
    disabledFromPartialPayment() {
      return !!this.entityDetail && this.entityDetail.paidQuantity > 0 
    }
  },
  watch: {
    value(newVal) {
      this.initializeVariables(newVal);
    },
    formValid(newVal) {
      this.$emit("updateValid", newVal);
    },
    openDialog(newVal) {
      this.localOpenDialog = newVal;
    },
    permission(newVal) {
      this.localPermission = newVal
    }
  },
};
</script>