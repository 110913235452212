<template>
  <v-form v-model="formValid">
    <v-row class="pt-3" v-if="status == 'activeInDates' || status == 'active'">
      <v-col>
        <DateTimePicker
          filled
          v-model="activeFrom"
          label="Attiva dalla data"
          clearable
          :show-time="false"
          @input="updateObject"
          date-picker-width="300px"
        ></DateTimePicker>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          filled
          hide-details="auto"
          v-model="itemGained"
          :items="[
            { text: 'Sconto percentuale', value: 'discount' },
            { text: 'Sconto a valore', value: 'amount' }
          ]"
          label="Tipo di sconto"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="itemGained == 'discount'">
      <v-col>
        <v-text-field
          :rules="[greaterThan0, lessThan100]"
          dense
          filled
          hide-details="auto"
          v-model="discount"
          label="Sconto"
          type="number"
          prepend-icon="mdi-percent"
          @input="updateObject"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="itemGained == 'amount'">
      <v-col>
        <v-text-field
          :rules="[]"
          dense
          filled
          hide-details="auto"
          v-model="amount"
          label="Importo"
          prepend-icon="mdi-currency-eur"
          @input="updateObject"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from 'vue'
import DateTimePicker from '@/components/common/DateTimePicker.vue'
import promoForm from '@/services/promos/promo.form.js'
import DurationPicker from '@/components/common/DurationPicker.vue'
import GroupedSelectList from '@/components/common/GroupedSelectList.vue';
import serviceTypeService from '@/services/serviceTypes/serviceTypes.service.js';
import itemTypeService from '@/services/itemTypes/itemTypes.service.js';
import ResponsiveCardSelector from "@/components/common/ResponsiveCardSelector.vue";
import { cloneDeep } from "lodash"

export default {
  name: "GeneralFormTab",
  components: {
    DurationPicker,
    GroupedSelectList,
    ResponsiveCardSelector,
    DateTimePicker
  },
  data: function() {
    return {
      id: undefined,
      name: 'Sconto Generale',
      description: 'Sconto Generale',
      status: 'active',
      activeFrom: new Date(),
      activeTo: undefined,
      loadingPromo: false,
      formValid: false,
      allServicesTarget: false,
      allServicesTargetQuantity: undefined,
      targetServices: [],
      targetServicesQuantity: {},
      allServicesValid: false,
      allItemsValid: true,
      newCustomer: false,
      validServices: [],
      serviceTypesGained: [],
      serviceTypesValid: [],
      serviceTypesTarget: [],
      paymentType: undefined,
      selectedPlaceholders: [],
      allActive: false,
      possiblePlaceholders: [
        { name: 'customer.firstname', text: 'Nome Cliente' },
        { name: 'customer.lastname', text: 'Cognome Cliente' },
        { name: 'customer.fullname', text: 'Nome Completo Cliente' },
      ],
      max255CharRule: (v) => {
        if(v) {
          return v.length <= 255 || 'Al massimo 255 caratteri'
        } else {
          return true
        }
      },
      presenceRule: (v) => {
        return !!v || 'Il campo è obbligatorio'
      },
      gainType: 'simple',
      gainTypeItems: [
        {
          name: 'target',
          text: 'A obiettivo',
          description: 'Il cliente deve raggiungere il determinato obiettivo per poterla guadagnare',
          icon: 'mdi-bullseye-arrow'
        },
        {
          name: 'simple',
          text: 'Semplice',
          description: 'La promozione viene assegnata da un operatore in cassa',
          icon: 'mdi-tag-outline'
        },
        {
          name: 'price',
          text: 'Costo',
          description: 'La promozione viene assegnata solo se il cliente è disposto a pagare l\'importo indicato',
          icon: 'mdi-tag-text-outline'
        },
      ],
      allServicesGained: false,
      allServicesGainedQuantity: undefined,
      itemGained: 'discount',
      itemGainedItems: [
        {
          name: 'discount',
          text: 'Sconto',
          description: 'Uno sconto da poter applicare ai servizi in percentuale',
          icon: 'mdi-label-percent'
        },
      ],
      price: undefined,
      discount: undefined,
      gainedServices: [],
      validDiscountServices: [],
      gainedServicesQuantity: {},
      validDiscountServicesQuantity: {},
      discount: undefined,
      amount: undefined,
      useTermsItems: [
        {
          name: 'range',
          text: 'Range',
          description: 'Può essere utilizzata infinite volte nel range specificato',
          icon: 'mdi-all-inclusive'
        },
      ],
      useTerms: 'oneShot',
      rangeMode: undefined,
      startDate: undefined,
      expirationDate: undefined,
      notes: '',
      notesReceipt: '',
      duration: undefined,
      localHtml: undefined,
      greaterThan0: (v) => {
        return (!v || (!isNaN(v) && v > 0)) || "Importo non valido";
      },
      lessThan100: (v) => {
        return (!v || (!isNaN(v) && v <= 100)) || "Percentuale non valida";
      },
    };
  },
  props: {
    bus: {
      type: Object,
      default: function() {
        return new Vue()
      }
    },
    nameGiftCard: {
      type: String,
      default: "",
    },
    descriptionGiftCard: {
      type: String,
      default: "",
    },
    customerGiftCard: {
      type: String,
      default: "",
    }
  },
  mounted: function() {
    this.handleObjectChanges(promoForm.promo)

    if(this.nameGiftCard){
      this.name = this.nameGiftCard
    }

    if(this.descriptionGiftCard){
      this.description = this.descriptionGiftCard + "" + this.customerGiftCard
    }

    var self = this
    promoForm.on('update', function(data) {
      self.handleObjectChanges(data.promo)
    })

    this.loadServiceTypes();

    this.bus.$on('resetDiscount', ()=>{ this.discount = undefined })
  },
  methods: {
    fields() {
      return [
        'id', 
        'name',
        'status',
        'description', 
        'notes', 
        'notesReceipt', 
        'gainType', 
        'price', 
        'itemGained', 
        'discount', 
        'amount',
        'useTerms',
        'rangeMode',
        'paymentType',
        'duration',
        'newCustomer',
        'allServicesTarget',
        'allServicesGained',
        'allServicesValid',
        'allItemsValid',
        'allServicesTargetQuantity',
        'allServicesGainedQuantity',
      ]
    },
    handleObjectChanges(promo) {
      const fields = this.fields()
      const newValKeys = Object.keys(promo)

      for(let i = 0; i < fields.length; i++) {
        const field = fields[i]
        if(newValKeys.includes(field) && promo[field] != this[field]) {
          this[field] = promo[field]
        }
      }

      if(!!promo.startDate) this.startDate = new Date(promo.startDate)
      if(!!promo.expirationDate) this.expirationDate = new Date(promo.expirationDate)
      if(!!promo.activeFrom) this.activeFrom = new Date(promo.activeFrom)
      if(!!promo.activeTo) this.activeTo = new Date(promo.activeTo)

      if(!!promo.gainedItems && promo.gainedItems.length != 0) {
        this.gainedServices = cloneDeep(promo.gainedItems.map(item => {
          this.gainedServicesQuantity[item.serviceId] = item.quantity
          return {
            id: Number(item.serviceId),
          }
        }))
      }

      if(!!promo.validDiscountItems && promo.validDiscountItems.length != 0) {
        this.validDiscountServices = cloneDeep(promo.validDiscountItems.map(item => {
          this.validDiscountServicesQuantity[item.serviceId] = item.quantity
          return {
            id: Number(item.serviceId),
          }
        }))
      }

      if(!!promo.targetItems && promo.targetItems.length != 0) {
        this.targetServices = cloneDeep(promo.targetItems.map(item => {
          this.targetServicesQuantity[item.serviceId] = item.quantity
          return {
            id: Number(item.serviceId),
          }
        }))
      }

      if(!!promo.validItems && promo.validItems.length != 0) {
        this.validServices = cloneDeep(promo.validItems.map(item => {
          return {
            id: Number(item.serviceId),
          }
        }))
      }
    },
    async loadServiceTypes() {
      await serviceTypeService.list().then((response) => {
        this.serviceTypesGained = cloneDeep(response.rows)
        this.serviceTypesValid = cloneDeep(response.rows)
        this.serviceTypesTarget = cloneDeep(response.rows)
      })
      
      this.applyAll('valid')
    },
    handleChangeType() {
      this.discount = undefined
      this.amount = undefined
      this.updateObject()
    },
    updateObject() {
      promoForm.updateObject(cloneDeep(this.sharedPromo))
    },
    addPlaceholder(name, text) {
      let placeholderToInsert = this.createPlaceholderDomElement(name, text)

      let range, html
      if(window.getSelection) {
        if(!this.isInEditableSelection()) return
        let selection = window.getSelection()

        if(selection.getRangeAt && selection.rangeCount) {
          range = selection.getRangeAt(0);
          range.collapse(false)
          range.insertNode(placeholderToInsert)
        }
      } else if (document.selection && document.selection.createRange) {
        range = document.selection.createRange();
        range.collapse(false);
        html = (placeholderToInsert.nodeType == 3) ? placeholderToInsert.data : placeholderToInsert.outerHTML;
        range.pasteHTML(html);
      }

      this.focusAndEdit()
    },    
    createPlaceholderDomElement(name, text) {
      let placeholderToInsert = document.createElement('span')
      placeholderToInsert.style.borderRadius = "16px"
      placeholderToInsert.style.fontSize = "14px"
      placeholderToInsert.style.height = "32px"
      placeholderToInsert.style.backgroundColor = "#8a77e2"
      placeholderToInsert.style.borderColor = "#8a77e2"
      placeholderToInsert.style.color = "white"
      placeholderToInsert.style.padding = "5px"
      placeholderToInsert.setAttribute("contenteditable", "false")
      placeholderToInsert.setAttribute("data-name", name)
      placeholderToInsert.innerHTML = text
      return placeholderToInsert
    },
    isInEditableSelection() {
      const element = this.getSelectionBoundaryElement(false)
      return !!element && (element.classList.contains('editable-message'))
    },
    getSelectionBoundaryElement(isStart) {
      let range, sel, container;
      if (document.selection) {
        range = document.selection.createRange();
        range.collapse(isStart);
        return range.parentElement();
      } else {
        sel = window.getSelection();
        if (sel.getRangeAt) {
          if (sel.rangeCount > 0) {
            range = sel.getRangeAt(0);
          }
        } else {
          // Old WebKit
          range = document.createRange();
          range.setStart(sel.anchorNode, sel.anchorOffset);
          range.setEnd(sel.focusNode, sel.focusOffset);

          // Handle the case when the selection was selected backwards (from the end to the start in the document)
          if (range.collapsed !== sel.isCollapsed) {
            range.setStart(sel.focusNode, sel.focusOffset);
            range.setEnd(sel.anchorNode, sel.anchorOffset);
          }
        }

        if (range) {
          container = range[isStart ? "startContainer" : "endContainer"];

          // Check if the container is a text node and return its parent if so
          return container.nodeType === 3 ? container.parentNode : container;
        }   
      }
    },
    calculateTextFromHtml() {
      this.notes = this.convertHtmlToText(this.$refs.editableMessage.innerHTML)
      //this.$emit('input', this.localValue)
      this.updateObject()
    },
    convertHtmlToText(html) {
      let template = this.$refs.hiddenEditableMessage
      html = html.trim()
      template.innerHTML = html

      this.selectedPlaceholders = []
      
      for(let i = 0; i < this.possiblePlaceholders.length; i += 1) {
        const name = this.possiblePlaceholders[i].name
        const spanElements = template.querySelectorAll('[data-name="' + name + '"]')

        if(spanElements.length > 0) {
          this.selectedPlaceholders.push(this.possiblePlaceholders[i])
        }

        for(let k = 0; k < spanElements.length; k += 1) {
          spanElements[k].parentNode.replaceChild(document.createTextNode('<' + name + '>'), spanElements[k])
        }
      }

      return this.parseChildNodesForValueAndLines(template)
    },
    parseChildNodesForValueAndLines(HtmlNode) {
      let newValue = '';
      let isOnFreshLine = true;

      for (let i = 0; i < HtmlNode.childNodes.length; i++) {
        const childNode = HtmlNode.childNodes[i];

        if (childNode.nodeName === 'BR') {
          newValue += '\n';
          isOnFreshLine = true;
          continue;
        }

        if (childNode.nodeName === 'DIV' && isOnFreshLine === false) {
          newValue += '\n';
        }

        isOnFreshLine = false;

        if (childNode.nodeType === 3 && childNode.textContent) {
          newValue += childNode.textContent;
        }

        newValue += this.parseChildNodesForValueAndLines(childNode);
      }

      return newValue
    },
    applyAll(type) {
      if(type == 'gained') {
        if (!this.allActive) {
        this.serviceTypesGained.map((cat) => {
          cat.services.map((el) => {
            if (this.gainedServices.filter(obj => obj.name == el.name).length === 0)
              this.gainedServices.push(el)
            })
          })
          this.allActive = true
        } else {
          this.gainedServices = []
          this.allActive = false
        }
      } else if(type == 'target') {
        if (!this.allActive) {
        this.serviceTypesTarget.map((cat) => {
          cat.services.map((el) => {
            if (this.targetServices.filter(obj => obj.name == el.name).length === 0)
              this.targetServices.push(el)
            })
          })
          this.allActive = true
        } else {
          this.targetServices = []
          this.allActive = false
        }
      } else if (type == 'valid') {
        if (!this.allActive) {
        this.serviceTypesValid.map((cat) => {
          cat.services.map((el) => {
            if (this.validDiscountServices.filter(obj => obj.name == el.name).length === 0)
              this.validDiscountServices.push(el)
            })
          })
          this.allServicesValid = true
          this.allActive = true
        } else {
          this.validDiscountServices = []
          this.allActive = false
        }
      }
      this.updateObject()
    },
    focusAndEdit() {
      let el = this.$refs.editableMessage

      el.focus()
      if(typeof window.getSelection != "undefined" && typeof document.createRange != "undefined") {
        let range = document.createRange()
        range.selectNodeContents(el)
        range.collapse(false)
        let sel = window.getSelection()
        sel.removeAllRanges()
        sel.addRange(range)
      } else if(typeof document.body.createTexRange != "undefined") {
        var textRange = document.body.createTextRange();
        textRange.moveToElementText(el);
        textRange.collapse(false);
        textRange.select();
      }
    },
    getPrimaryColor() {
      const isDark = this.$vuetify.theme.isDark
      if(isDark) return this.$vuetify.theme.themes.dark.primary
      else return this.$vuetify.theme.themes.light.primary
    },
    convertTextToHtml(text) {
      let finalText = text

      if(!finalText) finalText = ''

      for(let i = 0; i < this.possiblePlaceholders.length; i += 1) {
        const name = this.possiblePlaceholders[i].name
        const text = this.possiblePlaceholders[i].text
        const spanElement = this.createPlaceholderDomElement(name, text).outerHTML
        finalText = finalText.replaceAll('<' + name + '>', spanElement)
      }
      
      return finalText
    },
  },
  computed: {
    sharedPromo() {
      return {
        id: this.id,
        name: this.name,
        description: this.description,
        notes: this.notes,
        notesReceipt: this.notesReceipt,
        gainType: this.gainType,
        price: this.price,
        itemGained: this.itemGained,
        duration: this.duration,
        amount: this.amount,
        discount: this.discount,
        useTerms: this.useTerms,
        expirationDate: this.expirationDate,
        startDate: this.startDate,
        allServicesTarget: this.allServicesTarget,
        allServicesGained: this.allServicesGained,
        allServicesValid: this.allServicesValid,
        allItemsValid: this.allItemsValid,
        allServicesTargetQuantity: this.allServicesTargetQuantity,
        allServicesGainedQuantity: this.allServicesGainedQuantity,
        status: this.status,
        paymentType: this.paymentType,
        activeFrom: this.activeFrom,
        activeTo: this.activeTo,
        newCustomer: this.newCustomer,
        gainedItems: cloneDeep(this.gainedServices.map(service => ({
          serviceId: service.id,
          quantity: this.gainedServicesQuantity[service.id]
        }))),
        validDiscountItems: cloneDeep(this.validDiscountServices.map(service => ({
          serviceId: service.id,
          quantity: this.validDiscountServicesQuantity[service.id]
        }))),
        targetItems: cloneDeep(this.targetServices.map(service => ({
          serviceId: service.id,
          quantity: this.targetServicesQuantity[service.id]
        }))),
        validItems: cloneDeep(this.validServices.map(service => ({
          serviceId: service.id
        })))
      }
    },
    computedValid() {
      return this.formValid
    }
  },
  watch: {
    computedValid(newVal) {
      this.$emit('update:valid', newVal)
      if(this.bus) {
        this.bus.$emit('update:valid', newVal)
      }
      promoForm.setValid(newVal)
    },
    notes(newVal) {
      let newTextFromOutside = this.convertTextToHtml(newVal)

      this.$refs.editableMessage.innerHTML = newTextFromOutside
      this.focusAndEdit()
    }
  }
};
</script>

<style scoped>
.editable-message {
  border-radius: 5px;
  white-space: normal;
  background: rgba(193, 187, 187, 0.25);
  padding: 20px 20px 20px 15px;
  overflow: auto;
  word-wrap: break-word;
}

.chip {
  border-radius: 16px;
  font-size: 14px;
  height: 32px;
  background-color: #8a77e2 !important;
  border-color: #8a77e2 !important;
  color: white;
}

.hidden-editable-message {
  visibility: hidden;
  height: 0px;
}

.chip {
  border-radius: 16px;
  font-size: 14px;
  height: 32px;
  background-color: #8a77e2 !important;
  border-color: #8a77e2 !important;
  color: white;
}
</style>