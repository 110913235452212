<template>
  <v-container fluid>
    <v-row class="mt-1" v-if="showCustomBar">
      <v-col cols="auto">
        <v-btn color="primary" @click="openPromoForm">Crea promo su misura</v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn color="primary" @click="openGiftCardDialog"> GIFT CARD
          <v-icon class="ml-2">mdi-gift-open</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" @click="discountClick">
        <v-btn color="primary" @click="openPermanentPromoForm" :disabled="!canDiscount"> Convenzione
          <v-icon class="ml-2">mdi-account-heart</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" @click="discountClick">
        <v-btn color="primary" @click="openGeneralDiscountForm" :disabled="!canDiscount"> Sconto
          Generale
          <v-icon class="ml-2">mdi-cart-heart</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn color="primary" @click="openValidatePromoCode"> 
          Convalida codice promo
          <v-icon class="ml-2">mdi-barcode</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-skeleton-loader :types="{
          'item': 'list-item-avatar-two-line, divider'
        }" type="item@3" v-if="promosLoading">
        </v-skeleton-loader>
        <CollapsableList :items="promos" item-key="id" item-title="name" item-subtitle="description" v-else>
          <template v-slot:expansion="{item}">
            <PromoInfo :value="item"></PromoInfo>
          </template>
          <template v-slot:extra-icons="{item}">
            <v-btn icon class="ml-2" @click.stop.prevent="assignPromo(item)"
              :loading="!!loadingAdd && loadingAdd.id == item.id">
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </template>
        </CollapsableList>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-h6 ma-1">Promozioni attive</div>
        <div>
          <v-list>
            <div v-for="promoCustomer in localPromosCustomers" :key="promoCustomer.id">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title :class="{'red--text': promoCustomer.selected == 1}"
                    v-text="promoCustomer.promo.name"></v-list-item-title>
                  <v-list-item-subtitle>
                    <PromoInfo class="mt-2" :value="promoCustomer.promo" :promo-customer="promoCustomer"></PromoInfo>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-btn icon @click="removePromo(promoCustomer)"
                    :loading="!!loadingRemove && loadingRemove.id == promoCustomer.id">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-btn v-show="promoCustomer.selected !== true" icon @click="selectPromo(promoCustomer)"
                    :loading="!!loadingRemove && loadingRemove.id == promoCustomer.id">
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                </v-list-item-icon>
              </v-list-item>
              <v-divider></v-divider>
            </div>
          </v-list>
        </div>
      </v-col>
    </v-row>
    <PromosNewFrom v-if="promosForm" :open-dialog.sync="promosForm" :custom="true" :path-to-go-back="null" status="active"
      @submit="handleCustomPromoSubmit"></PromosNewFrom>

    <PromosPermanentNewForm :open-dialog.sync="promosPermanentForm" :custom="true" :path-to-go-back="null"
      @submit="handleCustomPromoSubmit" :close="closePermanentPromoForm"></PromosPermanentNewForm>

    <GeneralDiscountNewForm :open-dialog.sync="promosGeneralDiscountForm" :custom="true" :path-to-go-back="null"
      @submit="handleCustomPromoSubmit" :close="closeGeneralDiscountForm"></GeneralDiscountNewForm>

    <PromosPayDialog :dialog.sync="payDialog" :customer="localCustomer" :promo="payingPromo"
      :schedule-payments-settings="schedulePaymentsSettingsSelection" :customer-gift-card="customerGiftCard" 
      @reload="loadCustomer" @update:dialog="handlePayPromoDialogClose" @select-promo="selectPayPromo"
      :promo-code="promoBarcode"
    ></PromosPayDialog>

    <StandardDialog v-model="customerGiftCardDialog" :dialog-height="null" title="Gift Card" dialog-max-width="1000px">
      <CustomersAutocomplete class="mt-3" v-model="customerGiftCard" return-object :clearable="false"
        prepend-icon="mdi-account-arrow-right" :append-outer-icon="!!customer ? 'mdi-pencil' : undefined"
        label="Inserisci cognome di chi la riceve"></CustomersAutocomplete>

      <NumericKeyboardInput v-model="amountGiftCard" label="Valore Gift Card" class="mt-4"
        prepend-icon="mdi-currency-eur" live :min="0" rounded></NumericKeyboardInput>

      <DateTimePicker
        filled
        v-model="expirationDateGiftCard"
        label="Scadenza"
        :min="new Date()"
        :show-time="false"
        class="mt-4"
        date-picker-width="100%"
      ></DateTimePicker>

      <template v-slot:footer-actions>
        <div>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="closeCustomerGiftCardDialog">
            Chiudi
          </v-btn>
          <v-btn text color="default" @click="openGiftCardDialogForm">
            PAGA
          </v-btn>
        </div>
      </template>
    </StandardDialog>

    <StandardDialog v-model="dialogBarcodeDiscount" :dialog-height="null" dialog-max-width="500px" :persistent="true">
      <ManualBarcodeInput v-model="discountBarcode" @confirm="activeDiscount">
      </ManualBarcodeInput>
    </StandardDialog>

    <StandardDialog v-model="dialogBarcodePromo" :dialog-height="null" dialog-max-width="500px" :persistent="true">
      <ManualBarcodeInput 
        v-model="promoBarcode" 
        @confirm="validatePromoCode" 
        type="text"
        scanYourBarcodeMessage="Scannerizza codice promo ..."
      ></ManualBarcodeInput>
    </StandardDialog>

    <StandardDialog title="Valida codice promozione" v-model="dialogValidatePromoRecap" :dialog-height="null" dialog-max-width="700px" :persistent="true">
      <PromoInfo v-if="!!validatingPromo" class="mt-2" :value="validatingPromo"></PromoInfo>
      <div style="text-align: center" class="my-5" v-else-if="promoCodeAlreadyUsed">Codice promo già utilizzato 😡</div>
      <div style="text-align: center" class="my-5" v-else>Nessuna promo corrispondente al codice trovata 😢</div>
      <template v-slot:footer-actions>
        <div>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="closePromoRecapDialog">
            Chiudi
          </v-btn>
          <v-btn text color="default" v-if="!!validatingPromo" @click="() => {assignPromo(validatingPromo); closePromoRecapDialog()}">
            Assegna
          </v-btn>
        </div>
      </template>
    </StandardDialog>

    <StandardDialog v-model="scheduleDialog" :dialog-height="null" :dialog-width="null" :persistent="true"
      title="Vuoi pagare a rate?">
      <template v-slot:footer-actions>
        <div>
          <v-spacer></v-spacer>
          <v-btn text color="default" @click="scheduledPromo(true)">
            Paga a rate
          </v-btn>
          <v-btn text color="default" @click="scheduledPromo(false)">
            Paga tutto subito
          </v-btn>
        </div>
      </template>
    </StandardDialog>

    <SimplePopUp
      v-model="promoAmountAlert"
      :width="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '80vw' :
          $vuetify.breakpoint.xl ? '26vw' : 
          $vuetify.breakpoint.lg ? '30vw' : '35vw'"
      :alert-text="promoAmountAlertText"
    ></SimplePopUp>
  </v-container>
</template>

<script>
import Vue from 'vue'
import customerService from '@/services/customers/customers.service.js';
import promosService from '@/services/promos/promos.service.js';
import schedulePaymentsSettingsService from '@/services/schedulePaymentsSettings/schedulePaymentsSettings.service.js';
import CollapsableList from '@/components/common/CollapsableList.vue';
import StandardDialog from '@/components/common/StandardDialog.vue';
import PromoInfo from '@/components/areas/registries/customers/promos/PromosInfo';
import PromosNewFrom from '@/components/areas/payments/promos/PromosNewForm.vue';
import NewGiftCardPromoDialog from '@/components/areas/payments/promos/PromosNewForm.vue';
import smsService from '@/services/sms/sms.service.js';
import CustomersAutocomplete from '@/components/common/CustomersAutocomplete.vue';
import PromosPayDialog from '@/components/homepage/sections/PromosPayDialog.vue';
import Printer from '@/services/devices/rtPrinter';
import NumericKeyboardInput from '@/components/common/NumericKeyboardInput.vue';
import PromosPermanentNewForm from '@/components/areas/payments/promos/PromosPermanentNewForm.vue';
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import operatorService from '@/services/operators/operators.service.js'
import GeneralDiscountNewForm from '@/components/areas/payments/promos/GeneralDiscountNewForm.vue';
import cashDeskService from "@/services/cashDesks/cashDesks.service.js";
import DateTimePicker from '@/components/common/DateTimePicker.vue'
import SimplePopUp from '@/components/common/SimplePopUp.vue'

export default {
  name: "CustomerPromosForm",
  components: {
    CollapsableList,
    PromoInfo,
    PromosNewFrom,
    PromosPayDialog,
    StandardDialog,
    NewGiftCardPromoDialog,
    CustomersAutocomplete,
    NumericKeyboardInput,
    PromosPermanentNewForm,
    ManualBarcodeInput,
    GeneralDiscountNewForm,
    DateTimePicker,
    SimplePopUp
  },
  data: function () {
    return {
      localCustomer: this.customer,
      localPromosCustomers: [],
      promos: [],
      schedulePaymentsSettings: [],
      promosLoading: false,
      schedulePaymentsSettingsLoading: false,
      loadingRemove: undefined,
      customerGiftCardDialog: false,
      customerGiftCard: undefined,
      loadingAdd: undefined,
      amountGiftCard: undefined,
      expirationDateGiftCard: undefined,
      promosForm: false,
      promosPermanentForm: false,
      promosGeneralDiscountForm: false,
      payingPromo: undefined,
      payDialog: false,
      smsPromo: undefined,
      promoModelSelect: undefined,
      schedulePaymentsSettingsSelection: undefined,
      whatsappNumber: undefined,
      giftCardPromoObject: {
        name: "Gift Card",
        description: "",
        price: "",
        amount: "",
        paymentType: "total",
        allItemsValid: true,
        allServicesValid: true,
        activeFrom: new Date(),
        status: "active",
        rangeMode: "dates",
        useTerms: "untillEndRange",
        startDate: new Date(),
        itemGained: "amount",
        custom: true,
        allServicesGained: false,
        allServicesTarget: false,
        gainType: "price",
      },
      canDiscount: false,
      dialogBarcodeDiscount: false,
      dialogBarcodePromo: false,
      dialogValidatePromoRecap: false,
      validatingPromo: undefined,
      promoBarcode: undefined,
      promoCodeAlreadyUsed: false,
      discountBarcode: undefined,
      scheduleDialog: false,
      tmpPromo: undefined,
      promoAmountAlert: false,
      promoAmountAlertText: 'Lo sconto a valore verrà ripartito a partire dal prodotto/servizio con prezzo più alto. ',
    }
  },
  props: {
    customer: {
      type: Object,
    },
    promosCustomers: {
      type: Array,
    },
    bus: {
      type: Object,
      default: () => new Vue()
    },
    showCustomBar: {
      type: Boolean,
      default: true
    }
  },
  mounted: function () {
    this.bus.$on('closePromos', this.close)

    if (!this.customer || !this.customer.id) {
      throw new Error('at least specify customer id')
    } else {
      this.loadCustomer()
      this.loadschedulePaymentsSettings()
    }

    this.loadPromos()
  },
  methods: {
    async loadCustomer() {
      const newCustomer = await customerService.get(this.customer.id)
      this.localCustomer = newCustomer

      this.localPromosCustomers = newCustomer.promosCustomers
      this.$emit('update:promos-customers', this.localPromosCustomers)
      
      if (this.localPromosCustomers.length > 0) {
        this.localPromosCustomers.sort((a, b) => Number(b.selected) - Number(a.selected))
      }
    },
    async loadPromos() {
      this.promosLoading = true
      const response = await promosService.list(undefined, undefined, { status: 'active', rangeStatus: 'active' })
      this.promos = response.rows
      this.promosLoading = false
    },
    async loadschedulePaymentsSettings() {
      this.schedulePaymentsSettingsLoading = true
      const response = await schedulePaymentsSettingsService.list()
      this.schedulePaymentsSettings = response.rows
      this.schedulePaymentsSettingsLoading = false
    },
    async openPromoForm() {
      let openedCashDesk = await cashDeskService.getOpened()
      if(!openedCashDesk){
        this.$delegates.snackbar('Per creare una promo personalizzata aprire la cassa')
        return
      } 
      this.promosForm = true
    },
    openPermanentPromoForm() {
      this.promosPermanentForm = true
    },
    openValidatePromoCode() {
      this.dialogBarcodePromo = true
    },
    async validatePromoCode() {
      let foundedPromo = await promosService.validatePromoCode({ barcode: this.promoBarcode }, { id: this.customer.id })
      this.dialogBarcodePromo = false
      if(!!foundedPromo && foundedPromo.status == 'usable') {
        this.validatingPromo = await promosService.get(foundedPromo.promo.id)
        this.promoCodeAlreadyUsed = false
      } else if(!!foundedPromo && foundedPromo.status == 'alreadyUsed') {
        this.validatingPromo = undefined
        this.promoCodeAlreadyUsed = true
      } else {
        this.validatingPromo = undefined
        this.promoCodeAlreadyUsed = false
      }
      this.dialogValidatePromoRecap = true
    },
    closePromoRecapDialog() {
      this.dialogValidatePromoRecap = false
      this.validatingPromo = undefined
      this.promoBarcode = undefined
    },
    closePermanentPromoForm() {
      this.promosPermanentForm = false
      this.canDiscount = false
    },
    openGeneralDiscountForm() {
      this.promosGeneralDiscountForm = true
    },
    closeGeneralDiscountForm() {
      this.promosGeneralDiscountForm = false
      this.canDiscount = false
    },
    scheduledPromo(ok) {
      if (!ok)
        this.schedulePaymentsSettingsSelection = undefined
      this.payingPromo = this.tmpPromo
      this.scheduleDialog = false
      this.payDialog = true
    },
    assignPromo(promo) {
      if (!this.customerGiftCard) {
        if (promo.gainType == 'price') {
          if (promo.paymentType == 'scheduled') {
            for (let i = 0; i < this.schedulePaymentsSettings.length; i++) {
              if (Number(promo.price) >= Number(this.schedulePaymentsSettings[i].amountFrom) && Number(promo.price) <= Number(this.schedulePaymentsSettings[i].amountTo)) {
                // QUI
                let ok = true
                if (ok) {
                  this.schedulePaymentsSettingsSelection = this.schedulePaymentsSettings[i]
                  break
                }
              }
              else
                this.schedulePaymentsSettingsSelection = undefined
            }
            this.payingPromo = promo
            this.payDialog = true
          } else {
            this.payingPromo = promo
            this.payDialog = true
          }
        } else {
          this.loadingAdd = promo
          promosService.addCustomer(promo, this.localCustomer, undefined, this.promoBarcode).then(async() => {
            if (!!this.smsPromo) {
              let phoneNumbers = []
              if (!!this.promoModelSelect) {
                this.promoModelSelect = this.promoModelSelect.replace('<customer.firstname>', this.localCustomer.firstname)
                this.promoModelSelect = this.promoModelSelect.replace('<customer.lastname>', this.localCustomer.lastname)
                this.promoModelSelect = this.promoModelSelect.replace('<customer.fullname>', `${this.localCustomer.firstname} ${this.localCustomer.lastname}`)
                this.promoModelSelect = this.promoModelSelect.replace('<customer.namePromo>', `${promo.name}`)
                this.promoModelSelect = this.promoModelSelect.replace('<customer.descriptionPromo>', `${promo.description}`)

                phoneNumbers.push(this.localCustomer.prefixCellphone + this.localCustomer.cellphone)

                if (this.localCustomer.sendByWhatsapp == true) {
                  phoneNumbers[0] = phoneNumbers[0].replace("+", "")
                  smsService.sendWhatsappMessage(this.promoModelSelect, this.whatsappNumber, phoneNumbers[0]).then((result) => {
                    this.$delegates.snackbar("Messaggio Whatsapp inviato con successo");
                  })
                } else {
                  smsService.sendSmsEsendex(this.promoModelSelect, phoneNumbers, this.localCustomer.id).then(() => {
                    this.$delegates.snackbar("Sms inviato con successo");
                  })
                }

              } else {
                this.$delegates.snackbar("Impossibile inviare un messaggio se un modello non viene selezionato");
              }
            }
            try {
              await Printer.printPromoReport(promo, this.localCustomer)
            } catch (error) {
              console.log(error)
            }
            this.customerGiftCardDialog = false
            this.loadCustomer().then(async () => {
              if (!!this.localPromosCustomers.find(promoCustomer => promoCustomer.promo.id == promo.id)) {
                this.selectPromo(this.localPromosCustomers.find(promoCustomer => promoCustomer.promo.id == promo.id))
              }
              this.loadingAdd = undefined
            })
          })
        }
      } else {
        this.payingPromo = promo
        this.payDialog = true
      }
      this.$emit('close-dialog')
    },
    removePromo(promoCustomer) {
      if (promoCustomer.paymentTransaction !== undefined && promoCustomer.paymentTransaction !== null) {
        this.$delegates.snackbar("Promo a costo, transazione associata impossibile rimuovere")
        return
      }

      if (promoCustomer.promo.paymentType == 'scheduled') {
        let confirmed = confirm('Sei sicuro di voler eliminare la promo? Il cliente potrebbe avere dei sospesi ancora da pagare')
        if (confirmed) {
          this.loadingRemove = promoCustomer
          promosService.removeCustomer(promoCustomer, this.localCustomer).then(() => {
            this.loadCustomer().then(() => {
              this.loadingRemove = undefined
            })
          })
        } else {
          return
        }
      } else {
        this.loadingRemove = promoCustomer
        promosService.removeCustomer(promoCustomer, this.localCustomer).then(() => {
          this.loadCustomer().then(() => {
            this.loadingRemove = undefined
          })
        })
      }
    },
    async selectPayPromo(promo) {
      this.loadCustomer().then(() => {
        if (!!this.localPromosCustomers.find(promoCustomer => promoCustomer.promo.id == promo.id)) {
          this.selectPromo(this.localPromosCustomers.find(promoCustomer => promoCustomer.promo.id == promo.id))
        }
      })
    },
    async selectPromo(promoCustomer) {
      if (!!promoCustomer && !!promoCustomer.promo && !!promoCustomer.promo.amount) {
        this.promoAmountAlert = true
      }
      await promosService.selectPromoCustomer(promoCustomer)
      await this.loadCustomer()
    },
    handleCustomPromoSubmit(customPromo) {
      this.assignPromo(customPromo)
    },
    openGiftCardDialog() {
      this.customerGiftCardDialog = true
    },
    openGiftCardDialogForm() {
      if (Number(this.amountGiftCard) > 0) {
        if(this.expirationDateGiftCard != null && this.expirationDateGiftCard != undefined && this.expirationDateGiftCard != "") {
          this.giftCardPromoObject.price = this.amountGiftCard
          this.giftCardPromoObject.amount = this.amountGiftCard
          this.giftCardPromoObject.expirationDate = this.expirationDateGiftCard
          promosService.createCustom(this.giftCardPromoObject).then((newPromo) => {
            this.assignPromo(newPromo)
            console.log('Assign promo')
          })
        } else {
          this.$delegates.snackbar("Inserire una data di scadenza");
        }
      } else {
        this.$delegates.snackbar("Inserire l'importo");
      }

    },
    handlePayPromoDialogClose() {
      this.giftCardPromoObject = {
        name: "Gift Card",
        description: "",
        price: "",
        amount: "",
        paymentType: "total",
        allItemsValid: true,
        allServicesValid: true,
        activeFrom: new Date(),
        status: "active",
        rangeMode: "dates",
        useTerms: "untillEndRange",
        startDate: new Date(),
        itemGained: "amount",
        custom: true,
        allServicesGained: false,
        allServicesTarget: false,
        gainType: "price",
      }
      this.customerGiftCardDialog = false
    },
    closeCustomerGiftCardDialog() {
      this.amountGiftCard = undefined;
      this.customerGiftCardDialog = false
      this.customerGiftCard = undefined
    },
    discountClick() {
      if (!this.canDiscount) {
        this.dialogBarcodeDiscount = true
      }
    },
    activeDiscount() {
      operatorService.canPerformOperation(this.discountBarcode, "ApplyDiscounts").then((result) => {
        if (result) {
          this.canDiscount = true
        } else {
          alert("Non hai i permessi per eseguire questa azione!")
        }
      }).catch((err) => {
        console.error(err)
        alert("Badge non riconosciuto")
      })
      this.discountBarcode = undefined
      this.dialogBarcodeDiscount = false
    },
    close() {
      this.canDiscount = false
    }
  },
  watch: {
    customer() {
      if (!!this.customer && !!this.customer.id) {
        this.loadCustomer()
      }
    }
  },
  settings: {
    smsPromo: {
      bind: 'smsPromo'
    },
    promoModelSelect: {
      bind: 'promoModelSelect'
    },
    whatsappNumber: {
      bind: 'whatsappNumber'
    },
  }
}
</script>
