<template>
  <div class="d-flex flex-column flex-nowrap" style="height: 100%">
    <div 
      class="h3-text text-center font-weight-bold flex-grow-0 flex-shrink-0"
      style="min-height: 30px;"
      v-if="showTitle"
    >Operatori</div>
    <v-list 
      :class="{
        'elevation-3' : elevated
      }"
      class="flex-grow-1 flex-shrink-1" 
      style="max-height: 100%; overflow-y: scroll; border-radius: 5px"
    >
      <v-progress-circular
        v-if="localLoading || loading"
        indeterminate
        color="primary"
        style="display: block;margin-left: auto;margin-right: auto;"
      ></v-progress-circular>
      <template 
        v-else
        v-for="operator in operators"
      >
        <v-list-item
          link
          :key="operator.id"
          @click="$emit('click', operator)"
        >
          <v-list-item-avatar>
            <OperatorAvatar
              class="mr-1"
              :value="operator"
            ></OperatorAvatar>
          </v-list-item-avatar>
          <v-list-item-title>
            {{operator.firstname}}
          </v-list-item-title>
        </v-list-item>
        <v-divider :key="operator.id + '-divider'"></v-divider>
      </template>
    </v-list>
  </div>
</template>

<script>
import OperatorAvatar from '@/components/common/OperatorAvatar.vue';
import operatorService from '@/services/operators/operators.service.js'

export default {
  components: {
    OperatorAvatar
  },
  data: function() {
    return {
      operators: [ ],
      localLoading: false
    }
  },
  props: {
    showTitle: {
      type: Boolean,
      default: false
    },
    elevated: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  mounted: function() {
    this.loadOperators()
  },
  methods: {
    loadOperators() {
      this.localLoading = true
      operatorService.cachedList({filters: { notInStateOperator:'disabled' }}).then((results) => {
        this.operators = results
        this.localLoading = false
      })
    }
  },
};
</script>