import winoAxios from "./wino.axios";
import UrlKeeper from '@/services/UrlKeeper.js'

class WinoService {
  constructor() {

  }

  sendMessage(params) {
    return new Promise((resolve, reject) => {
      const apiUrl = UrlKeeper.getUrl('wino')
      winoAxios.post(apiUrl + '/webhook', params)
        .then(response => {
          resolve(response[0])
        }).catch(error => {
          reject(error);
        })
    })
  }
}

export default new WinoService()