<template>
  <FullScreenDialog
    v-model="promoDialog"
    title="Nuova Promozione"
    :routeFather="pathToGoBack"
    @input="$emit('update:open-dialog', $event)"
    :canClose="false"
  >
    <template v-slot:toolbar-extension>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab
          v-for="tab in promoTabs"
          :key="tab.key"
        >{{ tab.title }}</v-tab>
      </v-tabs>
    </template>
    <template v-slot:content>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="tabItem in promoTabs" :key="tabItem.key">
          <v-card flat>
            <component 
              :is="tabItem.component" 
              :name-gift-card="tabItem.props.nameGiftCard" 
              :description-gift-card="tabItem.props.descriptionGiftCard"
              :customer-gift-card="tabItem.props.customerGiftCard"
              :status="status"
              :bus="bus"
            ></component>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <template v-slot:footer-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="error"
        @click="closeDialog"
      >Annulla</v-btn>
      <v-btn
        text
        color="default"
        :disabled="!formValid"
        :loading="loading"
        @click="submitForm"
      >Salva</v-btn>
    </template>
  </FullScreenDialog>
</template>

<script>
import Vue from 'vue'

import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import GeneralForm from "@/components/areas/payments/promos/form/GeneralFormTab.vue"
import promosService from '@/services/promos/promos.service.js'
import promoForm from '@/services/promos/promo.form.js'

export default {
  name: "PromosNewForm",
  components: {
    FullScreenDialog,
    GeneralForm
  },
  data: function() {
    return {
      tab: null,
      promoDialog: this.openDialog,
      promoTabs: [
        {
          key: 0,
          title: "Generale",
          name: "general",
          component: "GeneralForm",
          props: {
            nameGiftCard: this.nameGiftCard,
            descriptionGiftCard: this.descriptionGiftCard,
            customerGiftCard: this.customerGiftCard
          }
        }
      ],
      formValid: false,
      promo: undefined,
      loading: false,
      originalUrl: window.location.pathname,
      bus: new Vue()
    };
  },
  mounted() {
    this.changeTabBasedOnProp(this.tabName)

    let self = this
    promoForm.resetPromo()
    promoForm.on('update', function(data) {
      self.promo = data.promo
    })

    promoForm.on('valid-change', function(data) {
      self.formValid = data
    })
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false
    },
    pathToGoBack: {
      type: String,
      default: "/payments/promos/list"
    },
    tabName: {
      type: String,
      default: 'general'
    },
    custom: {
      type: Boolean,
      default: false
    },
    nameGiftCard: {
      type: String,
      default: "",
    },
    descriptionGiftCard: {
      type: String,
      default: "",
    },
    customerGiftCard: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: undefined
    }
  },
  methods: {
    changeTabBasedOnProp(tabName) {
      for(let i = 0; i < this.promoTabs.length; i++) {
        if(this.promoTabs[i].name == tabName) {
          this.tab = i
          break
        }
      }
    },
    submitForm() {
      if(this.custom) {
        this.loading = true
        promosService.createCustom(this.promo).then((newPromo) => {
          this.closeDialog()
          this.loading = false
          this.$emit('submit', newPromo)
        })
      } else {
        if(this.promo.status == "active" && (!this.promo.activeFrom)) {
          this.$delegates.snackbar("Inserire la data di inizio promo");
          return;
        } else if (this.promo.status == "activeInDates" && (!this.promo.activeTo) && (!this.promo.activeFrom)) {
          this.$delegates.snackbar("Inserire il range di promo");
          return;
        } else if (!this.promo.gainType) {
          this.$delegates.snackbar("Selezionare come il cliente guadagna la promo");
          return;
        } else if (this.promo.gainType == "target" && this.promo.allServicesTarget == false && this.promo.targetItems.length == 0 ) {
          this.$delegates.snackbar("Selezionare l'obiettivo del cliente ");
          return;
        } else if (this.promo.itemGained == "services" && this.promo.allServicesGained == false && this.promo.gainedItems.length == 0 ) {
          this.$delegates.snackbar("Selezionare che servizi guadagna cliente ");
          return;
        } else if (!this.promo.useTerms) {
          this.$delegates.snackbar("Selezionare come il cliente userà la promo");
          return;
        } else if ((this.promo.useTerms == 'oneShotRange' || this.promo.useTerms == 'range') && this.promo.rangeMode == 'dates' && !this.promo.startDate) {
          this.$delegates.snackbar("Inserire correttamente i range di utilizzo");
          return;
        } else {
          this.loading = true
          promosService.create(this.promo).then((newPromo) => {
            this.closeDialog()
            this.loading = false
            this.$emit('submit', newPromo)
          })
        }
      }
    },
    closeDialog() {
      this.bus.$emit('reset')
      if(this.pathToGoBack) {
        this.$router.push({path: this.pathToGoBack})
      } else {
        this.promoDialog = false
        this.$emit('update:open-dialog', false)
      }
    }
  },
  watch: {
    openDialog(newVal) {
      this.promoDialog = newVal;
    },
    tab(newVal) {
      try {
        window.history.pushState("Tab", "Tab", this.originalUrl + '?tabName=' + this.promoTabs[newVal].name);
      } catch(err) {
        console.log('try setting the tab name but something goes wrong')
      }
    }
  },
  computed: {
  }
};
</script>